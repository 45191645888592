import React, { useEffect } from "react";
import cn from "classnames";
import Footer from "components/FOOTER";
import Headroom from "react-headroom";
import Header1 from "components/Header1";
import Header2 from "components/Header2";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";

import section1Styles from "./OurWork_section1.module.scss";
import section2Styles from "./OurWork_section2.module.scss";
import styles from "./OurWork.module.scss";
import { ArrowUp } from "react-bootstrap-icons";
import ScrollToTop from "react-scroll-to-top";

function RenderSection2(props) {
  const { t } = useTranslation();
  useEffect(() => {
    // define a callback function to be called when a DOM change is observed
    const handleMutations = () => {
      let videoTags = document.querySelectorAll("video"); // update the videoTags variable with the updated DOM
      videoTags.forEach((vid) => {
        console.log(vid);
        let userAgent = navigator.userAgent.toLowerCase();
        //   console.log(videoTags[i]);
        if (userAgent.indexOf("micromessenger") !== -1) {
          // Enable the controls on WeChat browser
          vid.controls = true;
        } else {
          // Disable the controls on other browsers
          vid.controls = false;
          vid.removeAttribute("controlsList");
        }
      });
    };

    // create a new MutationObserver and attach it to the DOM node you want to observe
    const observer = new MutationObserver(handleMutations);
    observer.observe(document.body, { childList: true, subtree: true });

    // call the callback function manually for the initial rendering
    handleMutations();

    // cleanup function to disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smooth scrolling
    });
  };

  return (
    <section className={`${section2Styles.section2} ${section1Styles.flexCol}`}>
      {/* <div className={section1Styles.flexCol}> */}
      <div className={section2Styles.flexCol1}>
        <h1 className={section2Styles.hero_title}>{t("our_work.poster")}</h1>
        <div className={section2Styles.flexRow}>
          <hr size={1} className={section2Styles.line} />
          <div className={section2Styles.flexRow__cell}>
            {/* <LazyLoad> */}
            <img
              src={require("assets/4207dc499c3a3bce491afed60307b70e.png")}
              alt="alt text"
              className={section2Styles.image3}
            />
            {/* </LazyLoad> */}
          </div>
        </div>
      </div>

      <div className={section1Styles.flexCol2}>
        {/* <div className={section1Styles.flexCol2__cell}> */}
        <div className={section1Styles.poster}>
          {/* <div className={section1Styles.poster__cell}> */}
          <LazyLoad className={`watermark3 ${section1Styles.poster__cell}`}>
            <img
              src={require("assets/b0e726e0ee75298f011c3cedfb4dc545.png")}
              alt="alt text"
              className={section1Styles.image4}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section1Styles.poster__cell1}> */}
          <LazyLoad className={`watermark ${section1Styles.poster__cell1}`}>
            <img
              src={require("assets/dc453350abd0b5810e1f08ec5bb8772f.png")}
              alt="alt text"
              className={section1Styles.image14}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section1Styles.poster__cell1}> */}
          <LazyLoad className={`watermark ${section1Styles.poster__cell1}`}>
            <img
              src={require("assets/8fef84a5543817dc83bcf46aaf883ded.png")}
              alt="alt text"
              className={section1Styles.image14}
            />
          </LazyLoad>
          {/* </div> */}
        </div>
        {/* </div> */}

        {/* <div className={section1Styles.flexCol2__cell1}> */}
        <div className={section1Styles.flexRow3}>
          {/* <div className={section1Styles.poster__cell1}> */}
          <LazyLoad className={`watermark ${section1Styles.poster__cell1}`}>
            <img
              src={require("assets/ad8cdc29b32eda897d43f8522a3470ac.png")}
              alt="alt text"
              className={section1Styles.image14}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section1Styles.poster__cell}> */}
          <LazyLoad className={`watermark3 ${section1Styles.poster__cell}`}>
            <img
              src={require("assets/548689a3482d2e80e86f94ec898f67f6.png")}
              alt="alt text"
              className={section1Styles.image4}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section1Styles.poster__cell1}> */}
          <LazyLoad className={`watermark ${section1Styles.poster__cell1}`}>
            <img
              src={require("assets/8af8eee910a4011e9a989fa49afbdf50.png")}
              alt="alt text"
              className={section1Styles.image14}
            />
          </LazyLoad>
          {/* </div> */}
        </div>
        {/* </div> */}

        {/* 第三行海报 */}
        {/* <div className={section1Styles.flexCol2__cell1}> */}
        <div className={section1Styles.flexRow4}>
          {/* <div className={section1Styles.poster1__cell}> */}
          <LazyLoad className={`watermark3 ${section1Styles.poster1__cell}`}>
            <img
              src={require("assets/24f07310243211734814ce881183d55f.png")}
              alt="alt text"
              className={section1Styles.poster1__image}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section1Styles.poster1__cell}> */}
          <LazyLoad className={`watermark3 ${section1Styles.poster1__cell}`}>
            <img
              src={require("assets/104a5c3a5e6c50b0aad8c894fe7f16bf.png")}
              alt="alt text"
              className={section1Styles.poster1__image}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section1Styles.poster1__cell}> */}
          <LazyLoad className={`watermark3 ${section1Styles.poster1__cell}`}>
            <img
              src={require("assets/71b8dddd7ba3ea5b0b79c693e4741ab2.png")}
              alt="alt text"
              className={section1Styles.poster1__image}
            />
          </LazyLoad>
          {/* </div> */}
        </div>
        {/* </div> */}
        {/* 第四行海报 */}
        {/* <div className={section1Styles.flexCol2__cell1}> */}
        <div className={section1Styles.flexRow5}>
          {/* <div className={section1Styles.poster3__cell}> */}
          <LazyLoad className={`watermark ${section1Styles.poster3__cell}`}>
            <img
              src={require("assets/85e53002f1dd5c8e90a914c206dc1b7c.png")}
              alt="alt text"
              className={section1Styles.image10}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section1Styles.poster3__cell}> */}
          <LazyLoad className={`watermark ${section1Styles.poster3__cell}`}>
            <img
              src={require("assets/aa09ae987e5869c462a5653649b02e96.png")}
              alt="alt text"
              className={section1Styles.image10}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section1Styles.poster3__cell1}> */}
          <LazyLoad className={`watermark ${section1Styles.poster3__cell1}`}>
            <img
              src={require("assets/da7988c74a7c7ed71f2705e9966e7bb5.png")}
              alt="alt text"
              className={section1Styles.image12}
            />
          </LazyLoad>
          {/* </div> */}
        </div>
        {/* </div> */}
        {/* 第五行海报 */}
        {/* <div className={section1Styles.flexCol2__cell1}> */}
        <div className={section1Styles.flexRow3}>
          {/* <div className={section1Styles.poster2__cell}> */}
          <LazyLoad className={`watermark3 ${section1Styles.poster2__cell}`}>
            <img
              src={require("assets/poster4.jpg")}
              alt="alt text"
              className={section1Styles.image4}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section1Styles.poster2__cell}> */}
          <LazyLoad className={`watermark3 ${section1Styles.poster2__cell}`}>
            <img
              src={require("assets/poster3.jpg")}
              alt="alt text"
              className={section1Styles.image4}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section1Styles.poster2__cell1}> */}
          <LazyLoad className={`watermark ${section1Styles.poster2__cell1}`}>
            <img
              src={require("assets/h.jpg")}
              alt="alt text"
              className={section1Styles.image14}
            />
          </LazyLoad>
          {/* </div> */}
        </div>
        {/* </div> */}
        {/* <div className={section1Styles.flexCol2__cell1}> */}
        <div className={section1Styles.flexRow4}>
          {/* <div className={section1Styles.flexRow4__cell}> */}
          <LazyLoad className={`watermark ${section1Styles.flexRow4__cell}`}>
            <img
              src={require("assets/happyblue.jpg")}
              alt="alt text"
              className={section1Styles.image22}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section1Styles.flexRow4__cell}> */}
          <LazyLoad className={`watermark ${section1Styles.flexRow4__cell}`}>
            <img
              src={require("assets/happygreen.jpg")}
              alt="alt text"
              className={section1Styles.image22}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section1Styles.flexRow4__cell5}> */}
          <LazyLoad className={`watermark ${section1Styles.flexRow4__cell5}`}>
            <img
              src={require("assets/simply.jpg")}
              alt="alt text"
              className={section1Styles.image22}
            />
          </LazyLoad>
          {/* </div> */}
        </div>
        {/* </div> */}
        {/* <div className={section1Styles.flexCol2__cell1}> */}
        <div className={section1Styles.flexRow3}>
          {/* <div className={section1Styles.flexRow3__cell}> */}
          <LazyLoad className={`watermark ${section1Styles.flexRow3__cell}`}>
            <img
              src={require("assets/wavy.jpg")}
              alt="alt text"
              className={section1Styles.image5}
            />
          </LazyLoad>
          {/* </div> */}
          <div className={section1Styles.flexRow3__cell1}></div>
          <div className={section1Styles.flexRow3__cell2}></div>
        </div>
        {/* </div> */}
      </div>

      <div className={section2Styles.ArrowUp} onClick={handleScrollToTop}></div>

      <ScrollToTop
        viewBox="0 0 256 256"
        smooth
        style={{
          borderRadius: "30px",
          backgroundColor: "rgb(45, 60, 31)",
          color: "white",
          borderColor: "rgb(45, 60, 31)",
          borderWidth: "3px",
          borderStyle: "solid",
          marginRight: "3%",
          width: "60px",
          height: "60px",
          border: "3px solid white",
          boxShadow: "none",
          animation: "linear",
        }}
        className={section2Styles.scroll2top}
        component={<ArrowUp size={30} />}
      />
      {/* </div> */}
    </section>
  );
}

export default function OurWork(props) {
  return (
    <React.Fragment>
      <Headroom tag="header" className="page-header">
        <nav className="max:show lg:hide">
          <Header1 />
        </nav>
        <nav className="lg:show">
          <Header2 />
        </nav>
      </Headroom>

      <main className={cn(styles.main, "our-work")}>
        {/* <div className={styles.main__cell}>{RenderSection2(props)}</div>
        <div className={styles.section3}>
          <Footer />
        </div> */}
        {RenderSection2(props)}
        <Footer />
      </main>
    </React.Fragment>
  );
}

OurWork.inStorybook = true;
