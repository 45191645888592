import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import section1Styles from "./OurWork_section1.module.scss";
import section2Styles from "./OurWork_section2.module.scss";
import styles from "./OurWork.module.scss";
import Footer from "components/FOOTER";
import Headroom from "react-headroom";
import Header1 from "components/Header1";
import Header2 from "components/Header2";
import LazyLoad from "react-lazyload";

import { ArrowUp } from "react-bootstrap-icons";
import ScrollToTop from "react-scroll-to-top";
import AnchorLink from "react-anchor-link-smooth-scroll";

function RenderSection1(props) {
  const { t } = useTranslation();
  useEffect(() => {
    // define a callback function to be called when a DOM change is observed
    const handleMutations = () => {
      let videoTags = document.querySelectorAll("video"); // update the videoTags variable with the updated DOM
      videoTags.forEach((vid) => {
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("micromessenger") !== -1) {
          // Enable the controls on WeChat browser
          vid.controls = true;
        } else {
          // Disable the controls on other browsers
          vid.controls = false;
          vid.removeAttribute("controlsList");
        }
      });
    };

    // create a new MutationObserver and attach it to the DOM node you want to observe
    const observer = new MutationObserver(handleMutations);
    observer.observe(document.body, { childList: true, subtree: true });

    // call the callback function manually for the initial rendering
    handleMutations();

    // cleanup function to disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);
  return (
    <section className={`${section1Styles.section1} ${section1Styles.flexCol}`}>
      {/* <div className={section1Styles.flexCol}> */}
      {/* <div className={section1Styles.flexRow}> */}
      {/* <div className={section1Styles.flexCol2}> */}
      <div className={section2Styles.flexRow1}>
        <div className={section2Styles.flexRow1__cell}>
          <AnchorLink href="#Animation">
            <h1 className={section2Styles.hero_title2}>
              {t("our_work.animation")}
            </h1>
            <LazyLoad className="watermark">
              <img
                src={require("assets/OurWork/Animation.jpeg")}
                alt="alt text"
                className={section1Styles.image}
              />
            </LazyLoad>
          </AnchorLink>
        </div>

        <div className={section2Styles.flexRow1__cell}>
          <AnchorLink href="#Poster">
            <h1 className={section2Styles.hero_title2}>
              {t("our_work.poster")}
            </h1>
            <LazyLoad className="watermark">
              <img
                src={require("assets/OurWork/Poster.jpeg")}
                alt="alt text"
                className={section1Styles.image}
              />
            </LazyLoad>
          </AnchorLink>
        </div>
      </div>
      <div className={section2Styles.flexRow2}>
        <div className={section2Styles.flexRow1__cell}>
          <AnchorLink href="#Branding">
            <h1 className={section2Styles.hero_title2}>
              {t("our_work.branding")}
            </h1>
            <LazyLoad className="watermark">
              <img
                src={require("assets/OurWork/IMG_4743.jpg")}
                alt="alt text"
                className={section1Styles.image}
              />
            </LazyLoad>
          </AnchorLink>
        </div>
        <div className={section2Styles.flexRow1__cell}>
          <AnchorLink href="#Photography">
            <h1 className={section2Styles.hero_title2}>
              {t("our_work.photography")}
            </h1>
            <LazyLoad className="watermark">
              <img
                src={require("assets/OurWork/Photography.jpeg")}
                alt="alt text"
                className={section1Styles.image}
              />
            </LazyLoad>
          </AnchorLink>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
    </section>
  );
}

function Animation(props) {
  const { t } = useTranslation();
  useEffect(() => {
    // define a callback function to be called when a DOM change is observed
    const handleMutations = () => {
      let videoTags = document.querySelectorAll("video"); // update the videoTags variable with the updated DOM
      videoTags.forEach((vid) => {
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("micromessenger") !== -1) {
          // Enable the controls on WeChat browser
          vid.controls = true;
        } else {
          // Disable the controls on other browsers
          vid.controls = false;
          vid.removeAttribute("controlsList");
        }
      });
    };

    // create a new MutationObserver and attach it to the DOM node you want to observe
    const observer = new MutationObserver(handleMutations);
    observer.observe(document.body, { childList: true, subtree: true });

    // call the callback function manually for the initial rendering
    handleMutations();

    // cleanup function to disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  return (
    <section
      className={`${section2Styles.section2} ${section2Styles.flexCol}`}
      id="Animation"
    >
      {/* <div className={section2Styles.flexCol}> */}
      <div className={section2Styles.flexCol1}>
        {/* <section id="Animation"> */}
        <h1 className={section2Styles.hero_title}>{t("our_work.animation")}</h1>
        {/* </section> */}
        <div className={section2Styles.flexRow}>
          <hr size={1} className={section2Styles.line} />
          <div className={section2Styles.flexRow__cell}>
            {/* <LazyLoad> */}
            <img
              src={require("assets/4207dc499c3a3bce491afed60307b70e.png")}
              alt="alt text"
              className={section2Styles.image3}
            />
            {/* </LazyLoad> */}
          </div>
        </div>
      </div>

      <div className={section2Styles.flexRow1}>
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <video
            src={require("assets/design.mp4")}
            type="video/mp4"
            alt="design"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell1}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell1}`}>
          <video
            src={require("assets/lessismore.mp4")}
            type="video/mp4"
            alt="lessismore"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <video
            src={require("assets/love.mp4")}
            type="video/mp4"
            alt="love"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
      </div>

      <div className={section2Styles.flexRow2}>
        {/* <div className={section2Styles.flexRow2__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow2__cell}`}>
          <video
            src={require("assets/naked_house_bubble.mp4")}
            type="video/mp4"
            alt="naked_house_bubble"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow2__cell1}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow2__cell1}`}>
          <video
            src={require("assets/happy_sunday.mp4")}
            type="video/mp4"
            alt="happy_sunday"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow2__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow2__cell}`}>
          <video
            src={require("assets/simply.mp4")}
            type="video/mp4"
            alt="simply"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
      </div>

      <div
        className={section2Styles.view_more}
        onClick={() => window.open("/Animation", "_self")}
      >
        {t("our_work.view_more_animation")}&nbsp;→
      </div>
      {/* </div> */}
    </section>
  );
}

function Poster(props) {
  const { t } = useTranslation();
  useEffect(() => {
    // define a callback function to be called when a DOM change is observed
    const handleMutations = () => {
      let videoTags = document.querySelectorAll("video"); // update the videoTags variable with the updated DOM
      videoTags.forEach((vid) => {
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("micromessenger") !== -1) {
          // Enable the controls on WeChat browser
          vid.controls = true;
        } else {
          // Disable the controls on other browsers
          vid.controls = false;
          vid.removeAttribute("controlsList");
        }
      });
    };

    // create a new MutationObserver and attach it to the DOM node you want to observe
    const observer = new MutationObserver(handleMutations);
    observer.observe(document.body, { childList: true, subtree: true });

    // call the callback function manually for the initial rendering
    handleMutations();

    // cleanup function to disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  return (
    <section
      className={`${section2Styles.section2} ${section2Styles.flexCol}`}
      id="Poster"
    >
      {/* <div className={section2Styles.flexCol}> */}
      <div className={section2Styles.flexCol1}>
        {/* <section id="Poster"> */}
        <h1 className={section2Styles.hero_title}>{t("our_work.poster")}</h1>
        {/* </section> */}
        <div className={section2Styles.flexRow}>
          <hr size={1} className={section2Styles.line} />
          <div className={section2Styles.flexRow__cell}>
            {/* <LazyLoad> */}
            <img
              src={require("assets/4207dc499c3a3bce491afed60307b70e.png")}
              alt="alt text"
              className={section2Styles.image3}
            />
            {/* </LazyLoad> */}
          </div>
        </div>
      </div>

      <div className={section1Styles.flexCol2}>
        <div className={section1Styles.poster}>
          {/* <div className={section1Styles.poster__cell}> */}
          <LazyLoad className={`watermark3 ${section1Styles.poster__cell}`}>
            <img
              src={require("assets/b0e726e0ee75298f011c3cedfb4dc545.png")}
              alt="alt text"
              className={section1Styles.image4}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section1Styles.poster__cell1}> */}
          <LazyLoad className={`watermark ${section1Styles.poster__cell1}`}>
            <img
              src={require("assets/dc453350abd0b5810e1f08ec5bb8772f.png")}
              alt="alt text"
              className={section1Styles.image14}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section1Styles.poster__cell1}> */}
          <LazyLoad className={`watermark ${section1Styles.poster__cell1}`}>
            <img
              src={require("assets/8fef84a5543817dc83bcf46aaf883ded.png")}
              alt="alt text"
              className={section1Styles.image14}
            />
          </LazyLoad>
          {/* </div> */}
        </div>
        <div className={section1Styles.flexRow3}>
          {/* <div className={section1Styles.poster__cell1}> */}
          <LazyLoad className={`watermark ${section1Styles.poster__cell1}`}>
            <img
              src={require("assets/ad8cdc29b32eda897d43f8522a3470ac.png")}
              alt="alt text"
              className={section1Styles.image14}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section1Styles.poster__cell}> */}
          <LazyLoad className={`watermark3 ${section1Styles.poster__cell}`}>
            <img
              src={require("assets/548689a3482d2e80e86f94ec898f67f6.png")}
              alt="alt text"
              className={section1Styles.image4}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section1Styles.poster__cell1}> */}
          <LazyLoad className={`watermark ${section1Styles.poster__cell1}`}>
            <img
              src={require("assets/8af8eee910a4011e9a989fa49afbdf50.png")}
              alt="alt text"
              className={section1Styles.image14}
            />
          </LazyLoad>
          {/* </div> */}
        </div>
      </div>

      <div
        className={section2Styles.view_more}
        onClick={() => window.open("/Poster", "_self")}
      >
        {t("our_work.view_more_poster")} →
      </div>
      {/* </div> */}
    </section>
  );
}

function Branding(props) {
  const { t } = useTranslation();
  useEffect(() => {
    // define a callback function to be called when a DOM change is observed
    const handleMutations = () => {
      let videoTags = document.querySelectorAll("video"); // update the videoTags variable with the updated DOM
      videoTags.forEach((vid) => {
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("micromessenger") !== -1) {
          // Enable the controls on WeChat browser
          vid.controls = true;
        } else {
          // Disable the controls on other browsers
          vid.controls = false;
          vid.removeAttribute("controlsList");
        }
      });
    };

    // create a new MutationObserver and attach it to the DOM node you want to observe
    const observer = new MutationObserver(handleMutations);
    observer.observe(document.body, { childList: true, subtree: true });

    // call the callback function manually for the initial rendering
    handleMutations();

    // cleanup function to disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  return (
    <section
      className={`${section2Styles.section2} ${section2Styles.flexCol}`}
      id="Branding"
    >
      {/* <div className={section2Styles.flexCol}> */}
      <div className={section2Styles.flexCol1}>
        {/* <section id="Branding"> */}
        <h1 className={section2Styles.hero_title}>{t("our_work.branding")}</h1>
        {/* </section> */}
        <div className={section2Styles.flexRow}>
          <hr size={1} className={section2Styles.line} />
          <div className={section2Styles.flexRow__cell}>
            {/* <LazyLoad> */}
            <img
              src={require("assets/4207dc499c3a3bce491afed60307b70e.png")}
              alt="alt text"
              className={section2Styles.image3}
            />
            {/* </LazyLoad> */}
          </div>
        </div>
      </div>

      {/* <div className={section1Styles.flexCol2}> */}
      <div className={section2Styles.flexRow1}>
        <div
          className={section2Styles.flexRow1__cell2}
          onClick={() => window.open("/Thenakedhouse ", "_self")}
        >
          <LazyLoad className="watermark">
            {/* <div className="watermark"> */}
            <img
              src={require("assets/nakedhouse1.png")}
              alt="alt text"
              className={section1Styles.image}
            />
            {/* </div> */}
          </LazyLoad>
          <h1 className={section2Styles.hero_title3}>
            {t("our_work.naked_house")}
          </h1>
        </div>
        <div
          className={section2Styles.flexRow1__cell2}
          onClick={() => window.open("/Wabisabi", "_self")}
        >
          <LazyLoad className="watermark">
            {/* <div className="watermark"> */}
            <img
              src={require("assets/Branding/wabisabi/cover.jpg")}
              alt="alt text"
              className={section1Styles.image}
            />
            {/* </div> */}
          </LazyLoad>
          <h1 className={section2Styles.hero_title3}>
            {t("our_work.wabi_sabi")}
          </h1>
        </div>
        <div className={section2Styles.flexRow1__cell}></div>
      </div>
      {/* </div> */}

      <div
        className={section2Styles.view_more}
        onClick={() => window.open("/Branding", "_self")}
      >
        {t("our_work.view_more_branding")} →
      </div>
      {/* </div> */}
    </section>
  );
}

function Photography(props) {
  const { t } = useTranslation();
  useEffect(() => {
    // define a callback function to be called when a DOM change is observed
    const handleMutations = () => {
      let videoTags = document.querySelectorAll("video"); // update the videoTags variable with the updated DOM
      videoTags.forEach((vid) => {
        let userAgent = navigator.userAgent.toLowerCase();
        //   console.log(videoTags[i]);
        if (userAgent.indexOf("micromessenger") !== -1) {
          // Enable the controls on WeChat browser
          vid.controls = true;
        } else {
          // Disable the controls on other browsers
          vid.controls = false;
          vid.removeAttribute("controlsList");
        }
      });
    };

    // create a new MutationObserver and attach it to the DOM node you want to observe
    const observer = new MutationObserver(handleMutations);
    observer.observe(document.body, { childList: true, subtree: true });

    // call the callback function manually for the initial rendering
    handleMutations();

    // cleanup function to disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smooth scrolling
    });
  };

  return (
    <section
      className={`${section2Styles.section2} ${section2Styles.flexColBottom}`}
      id="Photography"
    >
      {/* <div className={section2Styles.flexColBottom}> */}
      <div className={section2Styles.flexCol1}>
        {/* <section id="Photography"> */}
        <h1 className={section2Styles.hero_title}>
          {t("our_work.photography")}
        </h1>
        {/* </section> */}
        <div className={section2Styles.flexRow}>
          <hr size={1} className={section2Styles.line} />
          <div className={section2Styles.flexRow__cell}>
            {/* <LazyLoad className="watermark"> */}
            <img
              src={require("assets/4207dc499c3a3bce491afed60307b70e.png")}
              alt="alt text"
              className={section2Styles.image3}
            />
            {/* </LazyLoad> */}
          </div>
        </div>
      </div>

      <div className={section1Styles.flexCol2}>
        <div className={section2Styles.flexRow1}>
          {/* <div className={section2Styles.flexRow1__cell}> */}
          <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
            <img
              src={require("assets/Photography/1.jpg")}
              alt="alt text"
              className={section1Styles.image}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section2Styles.flexRow1__cell}> */}
          <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
            <img
              src={require("assets/Photography/2.jpg")}
              alt="alt text"
              className={section1Styles.image}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section2Styles.flexRow1__cell}> */}
          <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
            <img
              src={require("assets/Photography/3.jpg")}
              alt="alt text"
              className={section1Styles.image}
            />
          </LazyLoad>
          {/* </div> */}
        </div>
        <div className={section2Styles.flexRow2}>
          {/* <div className={section2Styles.flexRow1__cell}> */}
          <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
            <img
              src={require("assets/Photography/4.jpg")}
              alt="alt text"
              className={section1Styles.image}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section2Styles.flexRow1__cell}> */}
          <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
            <img
              src={require("assets/Photography/5.jpg")}
              alt="alt text"
              className={section1Styles.image}
            />
          </LazyLoad>
          {/* </div> */}
          {/* <div className={section2Styles.flexRow1__cell}> */}
          <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
            <img
              src={require("assets/Photography/6.jpg")}
              alt="alt text"
              className={section1Styles.image}
            />
          </LazyLoad>
          {/* </div> */}
        </div>
      </div>

      <div
        className={section2Styles.view_more}
        onClick={() => window.open("/Photography", "_self")}
      >
        {t("our_work.view_more_photography")} →
      </div>
      <div className={section2Styles.ArrowUp} onClick={handleScrollToTop}></div>

      <ScrollToTop
        viewBox="0 0 256 256"
        smooth
        style={{
          borderRadius: "30px",
          backgroundColor: "rgb(45, 60, 31)",
          color: "white",
          borderColor: "rgb(45, 60, 31)",
          borderWidth: "3px",
          borderStyle: "solid",
          marginRight: "3%",
          width: "60px",
          height: "60px",
          border: "3px solid white",
          boxShadow: "none",
          animation: "linear",
        }}
        className={section2Styles.scroll2top}
        component={<ArrowUp size={30} />}
      />
      {/* </div> */}
    </section>
  );
}

export default function OurWork(props) {
  return (
    <React.Fragment>
      <Headroom tag="header" className="page-header">
        <nav className="max:show lg:hide">
          <Header1 />
        </nav>
        <nav className="lg:show">
          <Header2 />
        </nav>
      </Headroom>

      <main className={cn(styles.main, "our-work")}>
        {/* <div className={styles.main__cell}>{RenderSection1(props)}</div>
        <div className={styles.main__cell}>{Animation(props)}</div>
        <div className={styles.main__cell}>{Poster(props)}</div>
        <div className={styles.main__cell}>{Branding(props)}</div>
        <div className={styles.main__cell}>{Photography(props)}</div>

        <div className={styles.section3}>
          <Footer />
        </div> */}
        {RenderSection1(props)}
        {Animation(props)}
        {Poster(props)}
        {Branding(props)}
        {Photography(props)}
        <Footer />
      </main>
    </React.Fragment>
  );
}

OurWork.inStorybook = true;
