import React, { useEffect } from "react";
import cn from "classnames";
import FOOTER from "components/FOOTER";
import Headroom from "react-headroom";
import Header1 from "components/Header1";
import Header2 from "components/Header2";
import { useTranslation } from "react-i18next";

import styles from "./Aboutuspage.module.scss";
import section1Styles from "./Aboutus_section1.module.scss";
import section2Styles from "./Aboutus_section2.module.scss";
import section3Styles from "./Aboutus_section3.module.scss";

function RenderSection1(props) {
  const { t } = useTranslation();
  useEffect(() => {
    // define a callback function to be called when a DOM change is observed
    const handleMutations = () => {
      let videoTags = document.querySelectorAll("video"); // update the videoTags variable with the updated DOM
      videoTags.forEach((vid) => {
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("micromessenger") !== -1) {
          // Enable the controls on WeChat browser
          vid.controls = true;
        } else {
          // Disable the controls on other browsers
          vid.controls = false;
          vid.removeAttribute("controlsList");
        }
      });
    };

    // create a new MutationObserver and attach it to the DOM node you want to observe
    const observer = new MutationObserver(handleMutations);
    observer.observe(document.body, { childList: true, subtree: true });

    // call the callback function manually for the initial rendering
    handleMutations();

    // cleanup function to disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  return (
    <section className={section1Styles.section1}>
      <div className={section1Styles.flexCol}>
        <div className={section1Styles.flexRow}>
          {/* <div className={section1Styles.flexRow__cell}> */}
          <h2
            className={`${section1Styles.medium_title} ${section1Styles.flexRow__cell}`}
          >
            {t("about_us_page.start")}
          </h2>
          {/* </div> */}
          <div className={section1Styles.flexRow__spacer} />
          <div className={section1Styles.flexRow__cell1}>
            {/* <div className={section1Styles.flexCol1}> */}
            <h1 className={section1Styles.hero_title}>
              {t("about_us_page.about_us")}
            </h1>
            <div className={section1Styles.subtitle}>
              <h3 className={section1Styles.subtitle_text}>
                {t("about_us_page.about_content1")}
              </h3>
              <h3 className={section1Styles.subtitle_text2}>
                {t("about_us_page.about_content2")}
              </h3>
              <h3 className={section1Styles.subtitle_text}>
                {t("about_us_page.about_content3")}
              </h3>
            </div>
            {/* </div> */}
          </div>
        </div>

        <div className={section1Styles.flexCol__cell + " watermark"}>
          <video
            src={require("assets/ms_aboutus.mp4")}
            type="video/mp4"
            alt="msaboutus-animation"
            className={section1Styles.image2}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </div>
      </div>
    </section>
  );
}

function RenderSection2(props) {
  const { t } = useTranslation();
  return (
    // section2Styles.section2
    <section className={section2Styles.flexRow}>
      {/* <div className={section2Styles.flexRow}> */}
      {/* <div className={section2Styles.flexRow__cell}> */}
      {/* <div className={section2Styles.flexCol}> */}
      <h1
        className={`${section2Styles.title} ${section2Styles.flexCol} ${section2Styles.flexRow__cell}`}
      >
        {t("about_us_page.today")}
      </h1>
      {/* </div> */}
      {/* </div> */}
      <div className={section2Styles.flexRow__spacer} />
      <div className={section2Styles.flexRow__cell1 + " watermark"}>
        <img
          src={require("assets/16b12e17e61ca458bc4ff086e2a24281.png")}
          alt="alt text"
          className={section2Styles.image}
        />
      </div>
      {/* </div> */}
    </section>
  );
}

function RenderSection3(props) {
  const { t } = useTranslation();
  return (
    <section className={`${section3Styles.section3} ${section3Styles.flexCol}`}>
      {/* <div className={section3Styles.flexCol}> */}
      <div className={section3Styles.flexRow}>
        <div className={section3Styles.flexRow__cell + " watermark"}>
          <img
            src={require("assets/e225ee798f1c04affdafca040fe7357e.png")}
            alt="alt text"
            className={section3Styles.image1}
          />
        </div>
        <div className={section3Styles.flexRow__spacer} />
        {/* <div className={section3Styles.flexRow__cell1}> */}
        <div
          className={`${section3Styles.flexCol1} ${section3Styles.flexRow__cell1}`}
        >
          <h1 className={section3Styles.hero_title1}>
            {t("about_us_page.our_mission")}
          </h1>

          {/* <div className={section3Styles.flexCol2}> */}
          <h3 className={`${section3Styles.vision} ${section3Styles.flexCol2}`}>
            <strong className={section3Styles.vision_strong}>
              {t("about_us_page.mission_fist_word")}
            </strong>
            <span className={section3Styles.vision_span}>
              {t("about_us_page.mission_content1")}
              {t("about_us_page.mission_content2")}
            </span>
          </h3>
          {/* </div> */}
        </div>
        {/* </div> */}
      </div>

      <div className={section3Styles.flexRow1}>
        {/* <div className={section3Styles.flexRow1__cell}> */}
        <div
          className={`${section3Styles.flexCol3} ${section3Styles.flexRow1__cell}`}
        >
          <h1 className={section3Styles.hero_title11}>
            {t("about_us_page.our_vision")}
          </h1>

          {/* <div className={section3Styles.flexCol2}> */}
          <h3 className={`${section3Styles.vision} ${section3Styles.flexCol2}`}>
            <strong className={section3Styles.vision_strong}>
              {t("about_us_page.vision_fist_word")}
            </strong>
            <span className={section3Styles.vision_span}>
              {t("about_us_page.vision_content1")}
              {t("about_us_page.vision_content2")}
            </span>
          </h3>
          {/* </div> */}
        </div>
        {/* </div> */}
        <div className={section3Styles.flexRow1__spacer} />
        <div className={section3Styles.flexRow1__cell1 + " watermark"}>
          <img
            src={require("assets/Aboutus-photo1.png")}
            alt="alt text"
            className={section3Styles.image11}
          />
        </div>
      </div>
      {/* </div> */}
    </section>
  );
}

export default function Aboutuspage(props) {
  return (
    <React.Fragment>
      <Headroom tag="header" className="page-header">
        <nav className="max:show lg:hide">
          <Header1 />
        </nav>
        <nav className="lg:show">
          <Header2 />
        </nav>
      </Headroom>

      <main className={cn(styles.main, "aboutus")}>
        {/* <div className={styles.main__cell}>{RenderSection1(props)}</div>
        <div className={styles.main__cell}>{RenderSection2(props)}</div>
        <div className={styles.main__cell}>{RenderSection3(props)}</div>
        <div className={styles.section4}>
          <FOOTER />
        </div> */}
        {RenderSection1(props)}
        {RenderSection2(props)}
        {RenderSection3(props)}
        <FOOTER />
      </main>
    </React.Fragment>
  );
}

Aboutuspage.inStorybook = true;
