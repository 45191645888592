import React from "react";
import cn from "classnames";
import FOOTER from "components/FOOTER";
import Headroom from "react-headroom";
import Header1 from "components/Header1";
import Header2 from "components/Header2";

import section1Styles from "./Careerspage_section1.module.scss";
import styles from "./Careerspage.module.scss";

function renderSection1(props) {
  return (
    <section className={section1Styles.section1}>
      <div className={section1Styles.flexCol}>
        <div className={section1Styles.flexCol1}>
          <h1 className={section1Styles.hero_title_box}>
            <span className={section1Styles.hero_title}>
              <span className={section1Styles.hero_title_span0}>
                Careers at
              </span>
              <span className={section1Styles.hero_title_span1}> </span>
              <span className={section1Styles.hero_title_span2}>
                <br />
              </span>
              <span className={section1Styles.hero_title_span3}>MILES SUN</span>
            </span>
          </h1>
          <h3 className={section1Styles.subtitle}>
            We are looking for passionate people to join us on our mission. We
            value flat hierarchies, clear communication and full ownership and
            responsibility.
          </h3>
        </div>

        <div className={section1Styles.content_box}>
          <h2 className={section1Styles.medium_title}>
            PLEASE STAY TUNED FOR UPCOMING JOB VACANCIES.
          </h2>
        </div>
      </div>
    </section>
  );
}

export default function Careerspage(props) {
  return (
    <React.Fragment>
      <Headroom tag="header" className="page-header">
        <nav className="max:show lg:hide">
          <Header1 />
        </nav>
        <nav className="lg:show">
          <Header2 />
        </nav>
      </Headroom>

      <main className={cn(styles.main, "careerspage")}>
        <div className={styles.main__item}>{renderSection1(props)}</div>
        <div className={styles.section2}>
          <FOOTER />
        </div>
      </main>
    </React.Fragment>
  );
}

Careerspage.inStorybook = true;
