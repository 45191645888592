import React, { useEffect } from "react";
import cn from "classnames";
import Footer from "components/FOOTER";
import Headroom from "react-headroom";
import Header1 from "components/Header1";
import Header2 from "components/Header2";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";

import section1Styles from "./OurWork_section1.module.scss";
import section2Styles from "./OurWork_section2.module.scss";
import styles from "./OurWork.module.scss";
import { ArrowUp } from "react-bootstrap-icons";
import ScrollToTop from "react-scroll-to-top";

function RenderSection2(props) {
  const { t } = useTranslation();
  useEffect(() => {
    // define a callback function to be called when a DOM change is observed
    const handleMutations = () => {
      let videoTags = document.querySelectorAll("video"); // update the videoTags variable with the updated DOM
      videoTags.forEach((vid) => {
        console.log(vid);
        let userAgent = navigator.userAgent.toLowerCase();
        //   console.log(videoTags[i]);
        if (userAgent.indexOf("micromessenger") !== -1) {
          // Enable the controls on WeChat browser
          vid.controls = true;
        } else {
          // Disable the controls on other browsers
          vid.controls = false;
          vid.removeAttribute("controlsList");
        }
      });
    };

    // create a new MutationObserver and attach it to the DOM node you want to observe
    const observer = new MutationObserver(handleMutations);
    observer.observe(document.body, { childList: true, subtree: true });

    // call the callback function manually for the initial rendering
    handleMutations();

    // cleanup function to disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smooth scrolling
    });
  };

  return (
    <section className={`${section1Styles.flexCol} ${section2Styles.section2}`}>
      {/* <div className={section1Styles.flexCol}> */}
      <div className={section2Styles.flexCol1}>
        <h1 className={section2Styles.hero_title}>
          {t("our_work.photography")}
        </h1>
        <div className={section2Styles.flexRow}>
          <hr size={1} className={section2Styles.line} />
          <div className={section2Styles.flexRow__cell}>
            {/* <LazyLoad> */}
            <img
              src={require("assets/4207dc499c3a3bce491afed60307b70e.png")}
              alt="alt text"
              className={section2Styles.image3}
            />
            {/* </LazyLoad> */}
          </div>
        </div>
      </div>

      <div className={section2Styles.flexRow1}>
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/1.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/2.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/3.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
      </div>
      <div className={section2Styles.flexRow2}>
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/4.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/5.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/6.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
      </div>
      <div className={section2Styles.flexRow2}>
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/7.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/8.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/9.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
      </div>
      <div className={section2Styles.flexRow2}>
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/10.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/11.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/12.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
      </div>
      <div className={section2Styles.flexRow2}>
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/13.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/22.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/15.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
      </div>
      <div className={section2Styles.flexRow2}>
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/16.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/17.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/18.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
      </div>
      <div className={section2Styles.flexRow2}>
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/19.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/20.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <img
            src={require("assets/Photography/21.jpg")}
            alt="alt text"
            className={section1Styles.image}
          />
        </LazyLoad>
        {/* </div> */}
      </div>
      {/* <div className={section2Styles.flexRow2}>
          <div className={section2Styles.flexRow1__cell}>
            <LazyLoad>
              <img
                src={require("assets/Photography/22.jpg")}
                alt="alt text"
                className={section1Styles.image}
              />
            </LazyLoad>
          </div>
          <div className={section2Styles.flexRow1__cell}></div>
          <div className={section2Styles.flexRow1__cell}></div>
        </div> */}
      <div className={section2Styles.ArrowUp} onClick={handleScrollToTop}></div>

      <ScrollToTop
        viewBox="0 0 256 256"
        smooth
        style={{
          borderRadius: "30px",
          backgroundColor: "rgb(45, 60, 31)",
          color: "white",
          borderColor: "rgb(45, 60, 31)",
          borderWidth: "3px",
          borderStyle: "solid",
          marginRight: "3%",
          width: "60px",
          height: "60px",
          border: "3px solid white",
          boxShadow: "none",
          animation: "linear",
        }}
        className={section2Styles.scroll2top}
        component={<ArrowUp size={30} />}
      />
      {/* </div> */}
    </section>
  );
}

export default function OurWork(props) {
  return (
    <React.Fragment>
      <Headroom tag="header" className="page-header">
        <nav className="max:show lg:hide">
          <Header1 />
        </nav>
        <nav className="lg:show">
          <Header2 />
        </nav>
      </Headroom>

      {/* <main className={cn(styles.main, "our-work")}>
        <div className={styles.main__cell}>{RenderSection2(props)}</div>
        <div className={styles.section3}>
          <Footer />
        </div>
      </main> */}
      {RenderSection2(props)}
      <Footer />
    </React.Fragment>
  );
}

OurWork.inStorybook = true;
