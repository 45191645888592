import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals.js";
import { ThreeDots } from "react-loader-spinner";
import 'bootstrap/dist/css/bootstrap.min.css';

import "normalize.css";
import "./common.css";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={
      <div className="loader">
        <ThreeDots
          height="500"
          width="200"
          radius="9"
          color="#5B5C39"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={false}
        />
      </div>
    }>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
