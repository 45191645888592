import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import FOOTER from "components/FOOTER";
import Headroom from "react-headroom";
import Header1 from "components/Header1";
import Header2 from "components/Header2";

import section1Styles from "./Companies_section1.module.scss";
import section2Styles from "./Companies_section2.module.scss";
import styles from "./Companies.module.scss";

function RenderSection1(props) {
  const { t } = useTranslation();
  useEffect(() => {
    // define a callback function to be called when a DOM change is observed
    const handleMutations = () => {
      let videoTags = document.querySelectorAll("video"); // update the videoTags variable with the updated DOM
      videoTags.forEach((vid) => {
        let userAgent = navigator.userAgent.toLowerCase();
        //   console.log(videoTags[i]);
        if (userAgent.indexOf("micromessenger") !== -1) {
          // Enable the controls on WeChat browser
          vid.controls = true;
        } else {
          // Disable the controls on other browsers
          vid.controls = false;
          vid.removeAttribute("controlsList");
        }
      });
    };

    // create a new MutationObserver and attach it to the DOM node you want to observe
    const observer = new MutationObserver(handleMutations);
    observer.observe(document.body, { childList: true, subtree: true });

    // call the callback function manually for the initial rendering
    handleMutations();

    // cleanup function to disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  return (
    <section className={`${section1Styles.section1} ${section1Styles.flexCol}`}>
      {/* <div className={section1Styles.flexCol}> */}
      <div className={section1Styles.flexCol1}>
        {/* <div className={section1Styles.flexCol1__cell}> */}
        <px-grid
          track-style="flex-grow: 1;"
          x="0px 1135fr 0px"
          y="0px 90fr 0px"
          xxs-x="0px 1135fr 0px"
          xxs-y="0px minmax(0px,50px) 0px"
        >
          <div className={section1Styles.flexRow}>
            {/* <div className={section1Styles.flexRow__cell}> */}
            <h1
              className={`${section1Styles.hero_title1_box} ${section1Styles.flexRow__cell}`}
            >
              <span className={section1Styles.hero_title1}>
                <span className={section1Styles.hero_title1_span0}>
                  {t("our_companies.our")}
                </span>
                <span className={section1Styles.hero_title1_span1}>
                  {" "}
                  {t("our_companies.companies")}
                </span>
              </span>
            </h1>
            {/* </div> */}
            {/* <div className={section1Styles.flexRow__spacer} /> */}
            <div className={section1Styles.flexRow__cell1}>
              <hr size={1} className={section1Styles.line1} />
            </div>
          </div>
        </px-grid>
        {/* </div> */}
        {/* <div className={section1Styles.flexCol1__cell1}> */}
        <div
          className={`${section1Styles.flexRow1} ${section1Styles.flexCol1__cell1}`}
        >
          <div className={section1Styles.flexRow1__cell}>
            <h3 className={section1Styles.subtitle}>
              {t("our_companies.solutions")}
              <span className={section1Styles.inline_block}>
                {t("our_companies.solutions_text1")}
              </span>
            </h3>
          </div>
          {/* <div className={section1Styles.flexRow1__spacer} /> */}
          <div className={section1Styles.flexRow1__cell1}>
            <h1 className={section1Styles.hero_title11_box}>
              <span className={section1Styles.hero_title11}>
                <span className={section1Styles.hero_title11_span0}>
                  {t("our_companies.building")}
                </span>
                <span className={section1Styles.hero_title11_span1}>
                  {" "}
                  {t("our_companies.brands")}
                </span>
              </span>
            </h1>
          </div>
        </div>
        {/* </div> */}
      </div>

      <div className={section1Styles.flexCol__cell + " watermark"}>
        <video
          src={require("assets/OurCompanies.mp4")}
          type="video/mp4"
          alt="milessun_logo_animation"
          className={section1Styles.image}
          autoPlay={true}
          muted={true}
          loop
          playsInline={true}
          onContextMenu={(e) => e.preventDefault()}
          controls
        />
      </div>
      <div className={section2Styles.flexRow2}>
        {/* <div className={section2Styles.flexRow2__cell}> */}
        <div
          className={`${section2Styles.flexCol1} ${section2Styles.flexRow2__cell}`}
        >
          <h2 className={section2Styles.medium_title1}>
            {t("our_companies.miles_sun")}
          </h2>
          <h4 className={section2Styles.highlights1}>
            {t("our_companies.miles_sun_content1")}
            <br />
            {t("our_companies.miles_sun_content2")}
          </h4>
        </div>
        {/* </div> */}
        <div className={section2Styles.flexRow2__spacer} />
      </div>
      {/* </div> */}
    </section>
  );
}

function RenderSection2(props) {
  const { t } = useTranslation();
  return (
    <section className={section2Styles.section2}>
      <div className={section2Styles.flexCol}>
        <div className={section2Styles.flexRow}>
          <div className={section2Styles.flexRow__cell}>
            <div className={section2Styles.flexRow__cell3}>
              <p className={section2Styles.hero_Subheading}>
                {t("our_companies.sister_companies")}
              </p>
              <div className={section2Styles.flexRow__cell1}>
                <img
                  src={require("assets/de880f94d7bbcc62bc19bf7be2dfd01e.png")}
                  alt="alt text"
                  className={section2Styles.line2}
                />
              </div>
              <div className={section2Styles.flexRow__spacer1} />
              <div className={section2Styles.flexRow__cell2}>
                <img
                  src={require("assets/53232d094cf8e3f19a6d1b63363ebc42.png")}
                  alt="alt text"
                  className={section2Styles.image2}
                />
              </div>
            </div>

            <h1 className={section2Styles.hero_title}>
              {t("our_companies.our_companies")}
            </h1>
          </div>
          {/* <div className={section2Styles.flexRow__spacer} /> */}
        </div>

        <div className={section2Styles.flexRow1}>
          <div className={section2Styles.flexRow1__cell + " watermark"}>
            <img
              src={require("assets/8a11eba56ecb9134924098489441c859.png")}
              alt="alt text"
              className={section2Styles.image3}
            />
          </div>
          <div className={section2Styles.flexRow1__spacer} />
          <div className={section2Styles.flexRow1__cell1}>
            {/* <div className={section2Styles.flexCol1}> */}
            <h2 className={section2Styles.medium_title}>
              {t("our_companies.china_bell")}
            </h2>
            <h4 className={section2Styles.highlights}>
              {t("our_companies.china_bell_content1")}
              <br />
              {t("our_companies.china_bell_content2")}
            </h4>
            {/* </div> */}
          </div>
        </div>

        <div className={section2Styles.flexRow3}>
          <div className={section2Styles.flexRow3__cell + " watermark"}>
            <img
              src={require("assets/e3ac95f8311abd3111eae6e32b8fde20.jpg")}
              alt="alt text"
              className={section2Styles.image32}
            />
          </div>
          <div className={section2Styles.flexRow3__spacer} />
          <div className={section2Styles.flexRow3__cell1}>
            {/* <div className={section2Styles.flexCol1}> */}
            <h2 className={section2Styles.medium_title}>
              {t("our_companies.green_solar")}
            </h2>
            <h4 className={section2Styles.highlights2}>
              {t("our_companies.green_solar_content1")}
              <br />
              {t("our_companies.green_solar_content2")}
            </h4>
            {/* </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default function Companies(props) {
  return (
    <React.Fragment>
      <Headroom tag="header" className="page-header">
        <nav className="max:show lg:hide">
          <Header1 />
        </nav>
        <nav className="lg:show">
          <Header2 />
        </nav>
      </Headroom>

      <main className={cn(styles.main, "companies")}>
        {/* <div className={styles.main__cell}>{RenderSection1(props)}</div>
        <div className={styles.main__cell}>{RenderSection2(props)}</div>
        <div className={styles.section3}>
          <FOOTER />
        </div> */}
        {RenderSection1(props)}
        {RenderSection2(props)}
          <FOOTER />
      </main>
    </React.Fragment>
  );
}

Companies.inStorybook = true;
