import React, { useState,useEffect  } from "react";
import cn from "classnames";
import Footer from "components/FOOTER";
import Headroom from "react-headroom";
import Header1 from "components/Header1";
import Header2 from "components/Header2";
import { useTranslation } from 'react-i18next';
import CurrencyList from "currency-list";

import styles from "./PaymentPage.module.scss";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
// import { act } from "react-dom/test-utils";
// import { data, error } from "jquery";
import { error } from "jquery";

// 这个定时器名称
let timer;
function RenderSection1(props) {
  const { t } = useTranslation();
  // const [message, setMessage] = useState("message");
  const [setMessage] = useState("message");
  // const [amount, setAmount] = useState("0.00");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [reference, setReference] = useState("");
  const [status,setStatus] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("USD"); // Default currency is USD

// 这个 useEffect 会在  description,reference,amount 变化时触发
useEffect(() => {
  // console.log("已更新:", description,reference,amount);
  // console.log("按钮状态",description&&reference&&amount);
  // console.log(Number(amount));
  if(description&&reference&&Number(amount)){
    // 设置按钮是否禁用
    setStatus(false);
    console.log("按钮可用");
  }else{
    // 设置按钮是否禁用
    setStatus(true);
    console.log("按钮不可用");
  }
}, [description,reference,amount]);
  const Currency = () => {
    //     console.log(CurrencyList.getAll().default.af);

    return (
      // 选择国家货币
      <select
        className={styles.country_select}
        id="currency"
        value={selectedCurrency}
        onChange={(e) => setSelectedCurrency(e.target.value)}
      >
        {Object.entries(CurrencyList.getAll().default.af).map(
          ([code, details]) => (
            <option key={code} value={code}>
              {details.name_plural} ({code})
            </option>
          )
        )}
      </select>
    );
  };
  // 设置一个函数，在用户停止输入的一秒后，补全小数
  const fillZero = () => {
    // 补零函数
    const zero = (text) => {
      if (text.includes('.')) {
        // 判断小数有几位
        const decimal = text.split('.')[1].length
        if (decimal === 1) {
          setAmount(text + "0");
        }
        if (decimal === 0) {
          setAmount(text + "00");
        }
      } else if (text === "") {
        setAmount(text + "00.00");
      } else {
        setAmount(text + ".00");
      }
    }
    return function (text) {
      if (timer) {
        clearTimeout(timer);
        timer = setTimeout(function () {
          zero(text);
        }, 1000);
      } else {
        timer = setTimeout(function () {
          zero(text);
        }, 1000);
      }
    }
  }
  const abc = fillZero();
  const formatCurrency = (input) => {
    // console.log(input.value);
    // setAmount(input.value);
    // if (typeof input.value === "string") {
    // const numericValue = parseFloat(input.value.replace(/[^\d.]/g, ""));

    const regular = /^\d*\.?\d{0,2}$/;
    if (regular.test(input.value) | input.value === '') {
      setAmount(input.value);
      abc(input.value);
    }
    // setAmount(` ${numericValue}`);
    /* if (!isNaN(numericValue)) {
      setAmount(` ${numericValue.toFixed(2)}`);
    } else {
      setAmount(" 0.00");
    }
    } */
  };

  /* function Message({ content }) {
    return <p>{content}</p>;
  } */

  const initialOptions = {
    // currency: "HKD",
    currency: "USD",
    intent: "capture", // or 'authorize' based on your needs

    "client-id":
      // "AfY610EEglBfedTPzVEa5vxFBKtG-Ko78tpLW3yDb4m7cW7PwjAp8doUxTW87_ePJ_2qfi1-JNDkXZJF",
      "AYj7Hi6fJwf-hB33x9rXNWgNQD6MjjFOqaj6sYndSNpEF2EMcgEaqwBaWBwoqaX5pt7SCAMThHOdX55h",
    "enable-funding": "card",
    "disable-funding": "paylater,venmo",
    "data-sdk-integration-source": "integrationbuilder_sc",
  };
  return (
    
    <section className={styles.payment}>
      <div className={styles.flexCol}>
        {/* <div
          className={styles.content_box}
          onClick={() =>
            window.open(
              "https://www.paypal.com/paypalme/jolchin?country.x=MY&locale.x=en_US",
              "_self"
            )
          }
        >
          <h2 className={styles.medium_title}>Paypal</h2>
        </div> */}
        {/* 描述 */}
        <label htmlFor="description">{t("payment.description")}</label>
        <input
          value={description}
          onChange={(e) => {
           setDescription(e.target.value);
          }}
          placeholder={t("payment.subscription")}
          type="text"
          id="description"
          autoComplete="off"
        ></input>

        <label htmlFor="reference">{t("payment.reference")}</label>
        <input placeholder={t("payment.reference_content")} value={reference}
          onChange={(e) => {
            setReference(e.target.value);
          }} autoComplete="off" type="text" id="reference"></input>

        <label htmlFor="currency">{t("payment.currency")}</label>
        <Currency />
        {/* <select id="currency">
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
          <option value="EUR">HKD</option>
        </select> */}

        <label htmlFor="amount" id="amountLabel">
          {t("payment.amount")}
        </label>
        {/* 金额输入框 */}
        <input
          value={amount}
          type="text"
          id="amount"
          onChange={(e) => formatCurrency(e.target)}
          placeholder="00.00"
          autoComplete="off"
        ></input>
        {/* <p>{description}</p>
        <p>{reference}</p> */}
        <div className={styles.gap}></div>
        <script src="https://www.paypal.com/sdk/js?client-id=AYj7Hi6fJwf-hB33x9rXNWgNQD6MjjFOqaj6sYndSNpEF2EMcgEaqwBaWBwoqaX5pt7SCAMThHOdX55h"></script>
        {/* <script src="https://www.paypal.com/sdk/js?client-id=AfY610EEglBfedTPzVEa5vxFBKtG-Ko78tpLW3yDb4m7cW7PwjAp8doUxTW87_ePJ_2qfi1-JNDkXZJF"></script> */}
        <div>
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
              disabled={status}
              style={{
                shape: "pill",
                layout: "vertical",
              }}
              createOrder={async (data, actions) => {
                // console.log("PayPalButtons");
                let amount = document.getElementById("amount");
                let description = document.getElementById("description");
                let reference = document.getElementById("reference");
                // console.log(amount.value,description.value,reference.value);
                //   console.log(amount);

                //   amount = parseFloat(amount.value.replace(/[^\d.]/g, ""));
                //       console.log(amount);

                //   try {
                //     const response = await fetch(
                //       "http://127.0.0.1:8888/api/orders",
                //       {
                //         method: "POST",
                //         headers: {
                //           "Content-Type": "application/json",
                //         },
                //         // use the "body" param to optionally pass additional order information
                //         // like product ids and quantities
                //         body: JSON.stringify({
                //           cart: [
                //             {
                //               id: "1",
                //               quantity: "1",
                //             },
                //           ],
                //         }),
                //       }
                //     );
                //     const orderData = await response.json();
                //     //     console.log("order data id:" + orderData.id);
                //     if (orderData.id) {
                //       console.log(orderData.id);
                //       return orderData.id;
                //     } else {
                //       console.log("order data id: problem!!" + orderData.id);

                //       const errorDetail = orderData?.details?.[0];
                //       const errorMessage = errorDetail
                //         ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                //         : JSON.stringify(orderData);
                //       console.error("throw errrorrr!!!");
                //       throw new Error(errorMessage);
                //     }
                //   } catch (error) {
                //     console.error("catched errrorrr!!!" + error);
                //     setMessage(`Could not initiate PayPal Checkout...${error}`);
                //   }
                // return null;
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        currency_code: "USD", // Change the currency code as needed
                        value: parseFloat(amount.value.replace(/[^\d.]/g, "")),
                        //     breakdown: {
                        //       item_total: { currency_code: "USD", value: "90.00" },
                        //       shipping: { currency_code: "USD", value: "5.00" },
                        //       handling: { currency_code: "USD", value: "2.00" },
                        //       tax_total: { currency_code: "USD", value: "8.00" },
                        //       shipping_discount: { currency_code: "USD", value: "-5.00" },
                        //     },
                      },
                      // description: "1", // Add the transaction description here
                      description: description.value, // Add the transaction description here
                      // description: description, // Add the transaction description here
                      //     items: [
                      //       {
                      //         name: "Item 1",
                      //         description: "Description of Item 1",
                      //         unit_amount: { currency_code: "USD", value: "50.00" },
                      //         quantity: "2",
                      //         category: "PHYSICAL", // or "DIGITAL"
                      //       },
                      //       // Additional items...
                      //     ],
                      //     shipping: {
                      //       method: "United States Postal Service",
                      //       address: {
                      //         name: { full_name: "John Doe" },
                      //         address_line_1: "123 Main St",
                      //         address_line_2: "Apartment 4",
                      //         admin_area_2: "San Jose",
                      //         admin_area_1: "CA",
                      //         postal_code: "95121",
                      //         country_code: "US",
                      //       },
                      //     },
                      //  invoice_id: "INV-123456",
                      phoneNum: "19864193936",
                      // reference_id: "2",
                      reference_id: reference.value,
                      // reference_id: reference,
                    },
                  ],
                });
              }}
              onApprove={async (data, actions) => {
                try {
                  //     const response = await fetch(
                  //       `/api/orders/${data.orderID}/capture`,
                  //       {
                  //         method: "POST",
                  //         headers: {
                  //           "Content-Type": "application/json",
                  //         },
                  //       }
                  //     );
                  //     const orderData = await response.json();
                  // Three cases to handle:
                  //     const errorDetail = orderData?.details?.[0];
                  //     if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                  //       // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                  //       // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                  //       return actions.restart();
                  //     } else if (errorDetail) {
                  //       // (2) Other non-recoverable errors -> Show a failure message
                  //       throw new Error(
                  //         `${errorDetail.description} (${orderData.debug_id})`
                  //       );
                  //     } else {
                  //       // (3) Successful transaction -> Show confirmation or thank you message
                  //       // Or go to another URL:  actions.redirect('thank_you.html');
                  //       const transaction =
                  //         orderData.purchase_units[0].payments.captures[0];
                  //       setMessage(
                  //         `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
                  //       );
                  //       console.log(
                  //         "Capture result",
                  //         orderData,
                  //         JSON.stringify(orderData, null, 2)
                  //       );
                  //     }

                  return actions.order.capture().then(function (details) {
                    console.log(details);
                    setMessage(`${details}`);
                    // alert(
                    //   "Transaction completed by " + details.payer.name.given_name
                    // );

                    //save to server db
                    // try {
                    //   const response = fetch("/api/orders", {
                    //     method: "POST",
                    //     headers: {
                    //       "Content-Type": "application/json",
                    //     },
                    //     body: JSON.stringify({
                    //       // details.create_time;
                    //     }),
                    //   });
                    //   const orderData = response.json();
                    //   console.log(orderData);
                    // } catch (error) {
                    //   console.error(error);
                    //   //   setMessage(`Could not initiate PayPal Checkout...${error}`);
                    // }
                  });
                } catch (error) {
                  console.error("error1" + error);
                  setMessage(
                    `Sorry, your transaction could not be processed...${error}`
                  );
                }
              }}
              onCancel={async (data, actions) => {
                alert("Transaction canceled !!!");
              }}
              onError={async (data, actions) => {
                console.error("error2 :" + error);
              }}
            />
          </PayPalScriptProvider>
        </div>

        {/* <Message content={message} /> */}
      </div>
    </section>

  );
}
export default function Payment(props) {
  return (
    <React.Fragment>
      <Headroom tag="header" className="page-header">
        <nav className="max:show lg:hide">
          <Header1 />
        </nav>
        <nav className="lg:show">
          <Header2 />
        </nav>
      </Headroom>

      <main className={cn(styles.main, "payment")}>
        <div className={styles.main__item}>{RenderSection1(props)}</div>
        <div className={styles.section3}>
          <Footer />
        </div>
      </main>
    </React.Fragment>
  );
}

Payment.inStorybook = true;
