import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import FOOTER from "components/FOOTER";
import Headroom from "react-headroom";
import Header1 from "components/Header1";
import Header2 from "components/Header2";

import section1Styles from "./Ourservices_section1.module.scss";
import styles from "./Ourservices.module.scss";

function RenderSection1(props) {
  const { t } = useTranslation();
  return (
    <section className={`${section1Styles.section1} ${section1Styles.flexCol}`}>
      {/* <div className={section1Styles.flexCol}> */}
        <div className={section1Styles.flexRow}>
          {/* <div className={section1Styles.flexRow__cell}> */}
          <h1
            className={`${section1Styles.hero_title} ${section1Styles.flexRow__cell}`}
          >
            {t("our_services.our_services")}
          </h1>
          {/* </div> */}
          <div className={section1Styles.flexRow__spacer} />
          {/* <div className={section1Styles.flexRow__cell1}> */}
          <h3
            className={`${section1Styles.subtitle} ${section1Styles.flexRow__cell1}`}
          >
            {t("our_services.services_content")}
          </h3>
          {/* </div> */}
        </div>

        <div className={section1Styles.flexCol1}>
          <div className={section1Styles.flexRow1}>
            <div className={section1Styles.flexRow1__cell}>
              {/* <div className={section1Styles.flexCol2}> */}
              <h1 className={section1Styles.title}>
                {t("our_services.market")}
              </h1>
              <h3
                className={`${section1Styles.subtitle1_box} ${section1Styles.subtitle1}`}
              >
                {/* <span className={section1Styles.subtitle1}> */}
                <span className={section1Styles.subtitle1_span1}>
                  {t("our_services.marketing_development")}
                </span>
                <span className={section1Styles.subtitle1_span2}>
                  {" "}
                  {t("our_services.marketing_development_content")}
                  <br />
                  <br />
                </span>
                <span className={section1Styles.subtitle1_span3}>
                  {t("our_services.brand_development")}
                </span>
                <span className={section1Styles.subtitle1_span4}>
                  {t("our_services.brand_development_content")}
                </span>
                {/* </span> */}
              </h3>
              {/* </div> */}
            </div>
            <div className={section1Styles.flexRow1__spacer} />
            <div className={section1Styles.flexRow1__cell1}>
              <img
                src={require("assets/Ourserices photo1.png")}
                alt="alt text"
                className={section1Styles.image1}
              />
            </div>
          </div>

          <px-grid
            x="0% 25.61% 76.39%"
            y="31px 53fr 0px"
            xl-x="0% 27.75% 72.25%"
            xl-y="55px 53fr 0px"
            lg-x="0% 26.97% 73.03%"
            lg-y="51px minmax(0px,43px) 0px"
            md-x="0% 33.84% 66.16%"
            md-y="41px minmax(0px,43px) 0px"
            sm-x="0% 41.54% 58.46%"
            sm-y="41px minmax(0px,43px) 0px"
            xs-x="0% 49.41% 50.59%"
            xs-y="33px minmax(0px,38px) 0px"
            xxs-x="0% 58.29% 41.71%"
            xxs-y="33px minmax(0px,38px) 0px"
            tn-x="0% 70.77% 29.23%"
            tn-y="33px minmax(0px,38px) 0px"
          >
            <div className={section1Styles.content_box}>
              <div
                onClick={() => window.open("/Work", "_self")}
                className={section1Styles.flexRow2}
              >
                {/* <div className={section1Styles.flexRow2__cell}> */}
                <h3
                  className={`${section1Styles.subtitle3} ${section1Styles.flexRow2__cell}`}
                >
                  {t("our_services.view_our_work")}
                </h3>
                {/* </div> */}
                <div className={section1Styles.flexRow2__cell1}>
                  <img
                    src={require("assets/d46a90d42b6aeb9c0e8aa18a745d77aa.png")}
                    alt="alt text"
                    className={section1Styles.image2}
                  />
                </div>
              </div>
            </div>
          </px-grid>
        </div>

        <div className={section1Styles.flexRow3}>
          <div className={section1Styles.flexRow3__cell}>
            <img
              src={require("assets/Ourserices photo2.png")}
              alt="alt text"
              className={section1Styles.image11}
            />
          </div>
          <div className={section1Styles.flexRow3__spacer} />
          {/* <div className={section1Styles.flexRow3__cell1}> */}
          <div
            className={`${section1Styles.flexCol3} ${section1Styles.flexRow3__cell1}`}
          >
            <h1 className={section1Styles.title}>
              {t("our_services.consultancy")}
            </h1>
            <h3 className={section1Styles.subtitle2}>
              {t("our_services.consultancy_content1")}
              <br />
              <br />
              {t("our_services.consultancy_content2")}
            </h3>
          </div>
          {/* </div> */}
        </div>
      {/* </div> */}
    </section>
  );
}

export default function Ourservices(props) {
  return (
    <React.Fragment>
      <Headroom tag="header" className="page-header">
        <nav className="max:show lg:hide">
          <Header1 />
        </nav>
        <nav className="lg:show">
          <Header2 />
        </nav>
      </Headroom>

      <main className={cn(styles.main, "ourservices")}>
        {/* <div className={styles.main__item}>{RenderSection1(props)}</div>
        <div className={styles.section2}>
          <FOOTER />
        </div> */}
        {RenderSection1(props)}
        <FOOTER />
      </main>
    </React.Fragment>
  );
}

Ourservices.inStorybook = true;
