import React from "react";
import { useTranslation } from 'react-i18next';
import cn from "classnames";
import FOOTER from "components/FOOTER";
import Headroom from "react-headroom";
import Header1 from "components/Header1";
import Header2 from "components/Header2";
import LazyLoad from "react-lazyload";

import section1Styles from "./WabiSabi_section1.module.scss";
import section2Styles from "./TheNakedHouse_section1.module.scss";
import styles from "./Homepage.module.scss";
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function RenderSection1(props) {
  const { t } = useTranslation();
  return (<section className={section1Styles.section1}>
    <div className={section1Styles.flexCol}>
      <Container fluid>
        {/* 标题 */}
        <div className={section1Styles.wab_title}>
          <h1 className={section1Styles.name}>{t("naked_house.title")}</h1>
          <hr size={1} className={section1Styles.line1} />
          <img
            src={require("assets/53232d094cf8e3f19a6d1b63363ebc42.png")}
            alt="alt text"
            className={section1Styles.image1}
          />
        </div>
        {/* 封面图片 */}
        <Row>
          <Col xs={12} sm={9} className={section1Styles.cover_box}>
            <div className="watermark">
              <img src={require("../assets/Branding/NakedHouse/5.jpg")}
              alt="alt text"
              className={section1Styles.image_cover}></img> 
            </div>
            
          </Col>
        </Row>
        <div className={section1Styles.street}>
          <Row>
            {/* 图片*/}
            <Col xs={7} sm={4} className={`offset-sm-3 offset-5`}>
            <div className="watermark">
              <img src={require("../assets/Branding/NakedHouse/6.jpg")} alt="alt text" className={section1Styles.image_street}></img> 
            </div>
            </Col>
            {/* 文字 */}
            <Col xs={12} sm={4}>
              <div className={section1Styles.line2}></div>
              <div>{t("naked_house.project_content")}</div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  </section >);
}

function RenderSection2(props) {
  const { t } = useTranslation();
  return (<section className={section1Styles.section1}>
    <div className={section1Styles.flexCol1}>
      <Container fluid>
        <div className={section1Styles.selected}>
          {/* 标题 */}
          <div className={section2Styles.flexbet}>
            <h1 className={section2Styles.selected_title}>
              <span>{t("naked_house.animation")}</span>
            </h1>
            <img
              src={require("assets/53232d094cf8e3f19a6d1b63363ebc42.png")}
              alt="alt text"
            />
          </div>
          <Row>
            <Col xs={12} sm={8}>
              <LazyLoad className="watermark">
                <video
                  src={require("assets/Branding/NakedHouse/nakedhouse video 1.mp4")}
                  type="video/mp4"
                  alt="design"
                  className={section2Styles.image}
                  autoPlay={true}
                  muted={true}
                  loop
                  playsInline={true}
                  onContextMenu={(e) => e.preventDefault()}
                // controls
                />
              </LazyLoad>
            </Col>
            <Col>
              <div className={section2Styles.star}>
                <img
                  src={require("assets/53232d094cf8e3f19a6d1b63363ebc42.png")}
                  alt="alt text"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={7} className="offset-sm-5">
              <LazyLoad className="watermark">
                <video
                  src={require("assets/Branding/NakedHouse/nakedhouse h2.mp4")}
                  type="video/mp4"
                  alt="design"
                  className={section2Styles.image2}
                  autoPlay={true}
                  muted={true}
                  loop
                  playsInline={true}
                  onContextMenu={(e) => e.preventDefault()}
                // controls
                />
              </LazyLoad>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  </section >);
}
function RenderSection3(props) {
  const { t } = useTranslation();
  return (<section className={section1Styles.section1}>
    <div className={section1Styles.flexCol1}>
      <Container fluid>
        <div className={section1Styles.selected}>
          {/* 标题 */}
          <h1 className={section1Styles.selected_title}>
            <span>{t("naked_house.poster")}</span>
          </h1>
          <Row>
            <Col xs={6} sm={5} className="offset-sm-2 watermark3">
                <img src={require("../assets/Branding/NakedHouse/7.jpg")}
                alt="alt text"
                className={section2Styles.poster_image}></img> 
              
            </Col>
            <Col xs={6} sm={5} className="watermark3">
            <div >
              <img src={require("../assets/Branding/NakedHouse/1.jpg")}
                alt="alt text"
                className={section2Styles.poster_image}></img> 
                </div>
            </Col>
          </Row>
          <div className={section2Styles.bottom_img}>
            <Row>
              <Col xs={6} sm={5} className="watermark3">
                <img src={require("../assets/Branding/NakedHouse/2.jpg")}
                  alt="alt text"
                  className={section2Styles.poster_image}></img>
              </Col>
              <Col xs={6} sm={5} className="watermark3">
                <img src={require("../assets/Branding/NakedHouse/3.jpg")}
                  alt="alt text"
                  className={section2Styles.poster_image}></img> 
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  </section >);
}

export default function Homepage(props) {
  return (
    <React.Fragment>
      <Headroom tag="header" className="page-header">
        <nav className="max:show lg:hide">
          <Header1 />
        </nav>
        <nav className="lg:show">
          <Header2 />
        </nav>
      </Headroom>

      <main className={cn(styles.main, "homepage")}>
        <div className={styles.main__item}>{RenderSection1(props)}</div>
        <div className={styles.main__item}>{RenderSection2(props)}</div>
        <div className={styles.main__item}>{RenderSection3(props)}</div>
        <div className={styles.section4}>
          <FOOTER />
        </div>
      </main>
    </React.Fragment>
  );
}

Homepage.inStorybook = true;
