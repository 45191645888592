import React, { useRef } from "react";
import { useTranslation } from 'react-i18next';
import cn from "classnames";
import section1Styles from "./WabiSabi_section1.module.scss";
import styles from "./WabiSabi.module.scss";
import Footer from "components/FOOTER";
// 页面滚动时实现吸顶或隐藏的效果
import Headroom from "react-headroom";
// react的延迟加载组件
// import LazyLoad from "react-lazyload";
import Header1 from "components/Header1";
import Header2 from "components/Header2";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function RenderSection1(props) {
    const { t } = useTranslation();
    return (<section className={section1Styles.section1}>
        <div className={section1Styles.flexCol}>
            <Container fluid>
                {/* 标题 */}
                <div className={section1Styles.wab_title}>
                    <h1 className={section1Styles.name}>{t("wabi_sabi.title")}</h1>
                    <hr size={1} className={section1Styles.line1} />
                    <img
                        src={require("assets/53232d094cf8e3f19a6d1b63363ebc42.png")}
                        alt="alt text"
                        className={section1Styles.image1}
                    />
                </div>
                {/* 封面图片 */}
                <Row>
                    {/* <Col xs={12} md={9} className={`${section1Styles.cover_box} offset-md-4`}> */}
                    <Col xs={12} sm={9} className={section1Styles.cover_box}>
                        <div className="watermark">
                            <img src={require("assets/Branding/wabisabi/cover.jpg")}
                                alt="alt text"
                                className={section1Styles.image_cover}></img>
                        </div>
                    </Col>
                </Row>
                <div className={section1Styles.street}>
                    <Row>
                        {/* 图片*/}
                        <Col xs={7} sm={4} className={`offset-sm-3 offset-5`}>
                            <div className="watermark">
                                <img src={require("assets/Branding/wabisabi/street.png")} alt="alt text" className={section1Styles.image_street}></img>
                            </div>
                        </Col>
                        {/* 文字 */}
                        <Col xs={12} sm={4}>
                            <div className={section1Styles.line2}></div>
                            <div>{t("wabi_sabi.project_content")}</div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    </section >);
}

function RenderSection2(props) {
    const { t } = useTranslation();
    const slider1Ref = useRef(null);
    const nextSlide1 = () => {
        slider1Ref.current.slickNext(); // 点击时切换到下一张图片
    };
    const prevSlide1 = () => {
        slider1Ref.current.slickPrev(); // 点击时切换到下一张图片
    };
    return (<section>
        <div className={section1Styles.flexCol1}>
            <Container fluid>
                {/* Selected Pages */}
                <div className={section1Styles.selected}>
                    {/* 标题 */}
                    <div className={section1Styles.flexbet}>
                        <h1 className={section1Styles.selected_title1}>
                            <span>{t("wabi_sabi.pages_title")}</span>
                        </h1>
                        <img
                            src={require("assets/53232d094cf8e3f19a6d1b63363ebc42.png")}
                            alt="alt text"
                        />
                    </div>
                    {/* 在电脑显示的图片盒子 */}
                    <div className={section1Styles.selected_box}>
                        <div className="watermark"><img src={require("assets/Branding/wabisabi/7.jpg")} alt="alt text"></img></div>
                        <div className="watermark"><img src={require("assets/Branding/wabisabi/6.jpg")} alt="alt text"></img></div>
                        <div className="watermark"><img src={require("assets/Branding/wabisabi/9.jpg")} alt="alt text"></img></div>
                        <div className="watermark"><img src={require("assets/Branding/wabisabi/10.jpg")} alt="alt text"></img></div>
                        <div className="watermark"><img src={require("assets/Branding/wabisabi/11.jpg")} alt="alt text"></img></div>
                        <div className="watermark"><img src={require("assets/Branding/wabisabi/12.jpg")} alt="alt text"></img></div>
                        <div className="watermark"><img src={require("assets/Branding/wabisabi/13.jpg")} alt="alt text"></img></div>
                        <div className="watermark"><img src={require("assets/Branding/wabisabi/14.jpg")} alt="alt text"></img></div>
                        <div className="watermark"><img src={require("assets/Branding/wabisabi/8.jpg")} alt="alt text"></img></div>
                    </div>
                </div>
                {/* 在手机显示的图片盒子 */}
                <div className={section1Styles.selected_mobile}>
                    <Slider
                        dots={false}
                        infinite={true}
                        speed={500}
                        slidesToShow={1}
                        slidesToScroll={1}
                        autoplay={false}
                        autoplaySpeed={2000}
                        cssEase="linear"
                        ref={slider1Ref}
                    >
                        <div className="watermark1"><img src={require("assets/Branding/wabisabi/7.jpg")} alt="alt text"></img></div>
                        <div className="watermark1"><img src={require("assets/Branding/wabisabi/6.jpg")} alt="alt text"></img></div>
                        <div className="watermark1"><img src={require("assets/Branding/wabisabi/9.jpg")} alt="alt text"></img></div>
                        <div className="watermark1"><img src={require("assets/Branding/wabisabi/10.jpg")} alt="alt text"></img></div>
                        <div className="watermark1"><img src={require("assets/Branding/wabisabi/11.jpg")} alt="alt text"></img></div>
                        <div className="watermark1"><img src={require("assets/Branding/wabisabi/12.jpg")} alt="alt text"></img></div>
                        <div className="watermark1"><img src={require("assets/Branding/wabisabi/13.jpg")} alt="alt text"></img></div>
                        <div className="watermark1"><img src={require("assets/Branding/wabisabi/14.jpg")} alt="alt text"></img></div>
                        <div className="watermark1"><img src={require("assets/Branding/wabisabi/8.jpg")} alt="alt text"></img></div>
                    </Slider>
                    <div className={section1Styles.arrow_box}>
                        <button className={section1Styles.arrow} onClick={prevSlide1}>←</button>
                        <button className={section1Styles.arrow} onClick={nextSlide1}>→</button>
                    </div>
                </div>
            </Container>
        </div>
    </section >);

}

function RenderSection3(props) {
    const { t } = useTranslation();
    const slider2Ref = useRef(null); // 创建一个 ref
    const nextSlide2 = () => {
        slider2Ref.current.slickNext(); // 点击时切换到下一张图片
    };
    const prevSlide2 = () => {
        slider2Ref.current.slickPrev(); // 点击时切换到下一张图片
    };
    return (<section>
        <div className={section1Styles.flexCol1}>
            <Container fluid>
                {/* Selected Photographs */}
                <div className={section1Styles.selected}>
                    {/* 标题 */}
                    <h1 className={section1Styles.selected_title}>
                        <span>{t("wabi_sabi.photographs_title")}</span>
                    </h1>
                    {/* 电脑的图片盒子 */}
                    <div className={section1Styles.photographs_box}>
                        <Row>
                            <Col className="watermark" sm={7}><img src={require("assets/Branding/wabisabi/1.jpg")} alt="alt text"></img></Col>
                            <Col className="watermark" sm={5}><img src={require("assets/Branding/wabisabi/2.jpg")} alt="alt text"></img></Col>
                        </Row>
                    </div>
                </div>
                {/* 手机的图片盒子 */}
                <div className={section1Styles.selected_mobile}>
                    <Slider
                        dots={false}
                        infinite={true}
                        speed={500}
                        slidesToShow={1}
                        slidesToScroll={1}
                        autoplay={false}
                        autoplaySpeed={2000}
                        cssEase="linear"
                        ref={slider2Ref}
                    >
                        {/* <div><img src={require("assets/Branding/wabisabi/1.jpg")} className={section1Styles.photographs_left} alt="alt text"></img></div>
                        <div><img src={require("assets/Branding/wabisabi/2.jpg")} className={section1Styles.photographs_right} alt="alt text"></img></div> */}
                        <div className={section1Styles.photographs_left + " watermark"}>

                        </div>
                        <div className={section1Styles.photographs_right + " watermark2"}>

                        </div>
                    </Slider>
                    <div className={section1Styles.arrow_box}>
                        <button className={section1Styles.arrow} onClick={prevSlide2}>←</button>
                        <button className={section1Styles.arrow} onClick={nextSlide2}>→</button>
                    </div>
                </div>


                {/* 底部盒子 */}
                <div className={section1Styles.footer}>
                    <Row>
                        <Col xs={12} sm={5}>
                            <div className={section1Styles.center}>
                                <div>
                                    <div className={section1Styles.line3}></div>
                                    <h3>{t("wabi_sabi.impermanent")}</h3>
                                    <p>{t("wabi_sabi.impermanent_content")}</p>
                                    <h3>{t("wabi_sabi.imperfect")}</h3>
                                    <p>{t("wabi_sabi.imperfect_content")}</p>
                                    <h3>{t("wabi_sabi.imcomplete")}</h3>
                                    <p>{t("wabi_sabi.imcomplete_content")}</p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={0} sm={1}></Col>
                        <Col xs={6} sm={3}>
                            <div className={section1Styles.center1}>
                                <div className="watermark">
                                    <img src={require("assets/Branding/wabisabi/17.JPG")} alt="alt text"></img>
                                </div>
                            </div>
                        </Col>
                        <Col xs={6} sm={3}>
                            <div className={section1Styles.two_photo}>
                                <div className="watermark">
                                    <img src={require("assets/Branding/wabisabi/15.JPG")} alt="alt text"></img>
                                </div>
                                <div className="watermark">
                                    <img src={require("assets/Branding/wabisabi/16.JPG")} className={section1Styles.top_right} alt="alt text"></img>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    </section>);

}

export default function WabiSabi(props) {
    return (
        <React.Fragment>
            <Headroom tag="header" className="page-header">
                <nav className="max:show lg:hide">
                    <Header1 />
                </nav>
                <nav className="lg:show">
                    <Header2 />
                </nav>
            </Headroom>

            <main className={cn(styles.main, "wabi-sabi")}>
                <div className={styles.main__cell}>{RenderSection1(props)}</div>
                <div className={styles.main__cell}>{RenderSection2(props)}</div>
                <div className={styles.main__cell}>{RenderSection3(props)}</div>
                <div className={styles.section3}>
                    <Footer />
                </div>
            </main>
        </React.Fragment>
    );
}

WabiSabi.inStorybook = true;