import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import FOOTER from "components/FOOTER";
import Headroom from "react-headroom";
import Header1 from "components/Header1";
import Header2 from "components/Header2";

import section1Styles from "./Homepage_section1.module.scss";
import section2Styles from "./Homepage_section2.module.scss";
import section3Styles from "./Homepage_section3.module.scss";
import styles from "./Homepage.module.scss";

function RenderSection1(props) {
  const { t } = useTranslation();
  useEffect(() => {
    document.getElementById("videoId").controlsList = "nodownload";
    // video1.controlsList = "nodownload";
    // define a callback function to be called when a DOM change is observed
    const handleMutations = () => {
      let videoTags = document.querySelectorAll("video"); // update the videoTags variable with the updated DOM
      videoTags.forEach((vid) => {
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("micromessenger") !== -1) {
          // Enable the controls on WeChat browser
          vid.controls = true;
        } else {
          // Disable the controls on other browsers
          vid.controls = false;
          vid.removeAttribute("controlsList");
        }
      });
    };

    // create a new MutationObserver and attach it to the DOM node you want to observe
    const observer = new MutationObserver(handleMutations);
    observer.observe(document.body, { childList: true, subtree: true });

    // call the callback function manually for the initial rendering
    handleMutations();

    // cleanup function to disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  return (
    <section className={section1Styles.section1}>
      <div className={section1Styles.flexCol}>
        {/* <div className={section1Styles.flexCol__cell}> */}
        <div className={section1Styles.flexRow}>
          {/* <div className={section1Styles.flexRow__cell}> */}
          <h1
            className={`${section1Styles.title} ${section1Styles.flexRow__cell}`}
          >
            {t("homepages.welcome.welcome")}
          </h1>
          {/* </div> */}
          <div className={section1Styles.flexRow__spacer} />
          {/* <div className={section1Styles.flexRow__cell1}> */}
          <div
            className={`${section1Styles.flexRow1} ${section1Styles.flexRow__cell1}`}
          >
            <div className={section1Styles.flexRow1__cell}>
              <hr size={1} className={section1Styles.line1} />
            </div>
            <div className={section1Styles.flexRow1__cell1}>
              <img
                src={require("assets/53232d094cf8e3f19a6d1b63363ebc42.png")}
                alt="alt text"
                className={section1Styles.image4}
              />
            </div>
          </div>
          {/* </div> */}
        </div>
        {/* </div> */}
        {/* <div className={section1Styles.flexCol__cell1}> */}
        {/* <div className={section1Styles.flex_align}> */}
        <div className={section1Styles.flexCol1__cell}>
          <div className={section1Styles.flex_align}>
            <h1 className={section1Styles.hero_title1}>
              {t("homepages.welcome.inspipe")}
            </h1>
            <img
              className={section1Styles.icon}
              src={require("assets/95372789f89c8f54b8c3e501c72aa5aa.png")}
              alt="alt text"
            />
          </div>

          <h1 className={section1Styles.hero_title1}>
            {t("homepages.welcome.innovate")}
          </h1>
        </div>
        {/* </div> */}
        {/* </div> */}
        <div className={section1Styles.flexCol__cell2}>
          <div className={section1Styles.flexRow2}>
            {/* 这里也要文字与图片对齐 */}
            {/* <div className={section1Styles.flexRow2__cell}> */}
            <h3
              className={`${section1Styles.subtitle} ${section1Styles.flexRow2__cell}`}
            >
              {t("homepages.welcome.believe")}
              {/* <br />
                <br /> */}
            </h3>
            {/* </div> */}
            <div className={section1Styles.flexRow2__spacer} />
            <div className={section1Styles.flexRow2__cell1 + " watermark"}>
              <video
                src={require("assets/milessun_logo_animation.mp4")}
                type="video/mp4"
                alt="milessun_logo_animation"
                className={section1Styles.image}
                autoPlay={true}
                muted={true}
                loop
                playsInline={true}
                onContextMenu={(e) => e.preventDefault()}
                controls
                controlsList="nodownload"
                id="videoId"
                x5-video-player-type="h5"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function RenderSection2(props) {
  const { t } = useTranslation();
  return (
    <section className={section2Styles.section2}>
      <div className={section2Styles.flexCol}>
        <div className={section2Styles.flexCol1}>
          <div className={section2Styles.flexRow}>
            {/* <div className={section2Styles.flexRow__cell}> */}
            <h1
              className={`${section2Styles.title} ${section2Styles.flexRow__cell}`}
            >
              {t("homepages.about_us.about_us")}
            </h1>
            {/* </div> */}
            <div className={section2Styles.flexRow__spacer} />
            <div className={section2Styles.flexRow__cell1}>
              <img
                src={require("assets/de880f94d7bbcc62bc19bf7be2dfd01e.png")}
                alt="alt text"
                className={section2Styles.line2}
              />
            </div>
            <div className={section2Styles.flexRow__spacer1} />
            <div className={section2Styles.flexRow__cell2}>
              <img
                src={require("assets/53232d094cf8e3f19a6d1b63363ebc42.png")}
                alt="alt text"
                className={section2Styles.image4}
              />
            </div>
          </div>

          <div className={section2Styles.flexRow1}>
            {/* <div className={section2Styles.flexRow1__cell}> */}
            <h1
              className={`${section2Styles.hero_title} ${section2Styles.flexRow1__cell}`}
            >
              {t("homepages.about_us.companies_name")}
            </h1>
            {/* </div> */}
            <div className={section2Styles.flexRow1__spacer} />
            {/* <div className={section2Styles.flexRow1__cell1}> */}
            <h3
              className={`${section2Styles.subtitle1} ${section2Styles.flexRow1__cell1}`}
            >
              {t("homepages.about_us.companies_team")}
              <br />
              <br />
            </h3>
            {/* </div> */}
          </div>
        </div>

        <div className={section2Styles.flexRow2}>
          <div className={section2Styles.flexRow2__cell}>
            {/* <div className={section2Styles.flexCol2}> */}
            <div className={section2Styles.rect} />
            <h3 className={section2Styles.subtitle2}>
              {t("homepages.about_us.our_mission")}
            </h3>
            <px-grid
              x="0px 540fr 0px"
              y="11px 189fr 0px"
              sm-x="0px 540fr 0px"
              sm-y="11px minmax(0px,189fr) 0px"
              xs-x="0px 540fr 0px"
              xs-y="11px minmax(0px,90px) 0px"
            >
              <h3 className={section2Styles.subtitle3}>
                {t("homepages.about_us.mission_content")}
                <br />
                <br />
                <br />
              </h3>
            </px-grid>
            {/* </div> */}
          </div>
          <div className={section2Styles.flexRow2__spacer} />
          <div className={section2Styles.flexRow2__cell}>
            {/* <div className={section2Styles.flexCol3}> */}
            <div className={section2Styles.rect1} />
            <h3 className={section2Styles.subtitle21}>
              {t("homepages.about_us.our_vision")}
            </h3>
            <h3 className={section2Styles.subtitle31}>
              {t("homepages.about_us.vision_content")}
            </h3>
            {/* </div> */}
          </div>
        </div>

        <px-grid
          x="0% 25.1% 81.9%"
          y="40px 53fr 0px"
          xl-x="0% 23.47% 76.53%"
          xl-y="40px 53fr 0px"
          lg-x="0% 22.69% 77.31%"
          lg-y="0px 53fr 0px"
          md-x="0% 28.36% 71.64%"
          md-y="0px 53fr 0px"
          sm-x="0% 35.04% 64.96%"
          sm-y="0px minmax(0px,34px) 0px"
          xs-x="0% 37.45% 62.55%"
          xs-y="0px minmax(0px,34px) 0px"
          xxs-x="0% 46.54% 53.46%"
          xxs-y="0px minmax(0px,34px) 0px"
          tn-x="0% 55.01% 44.99%"
          tn-y="0px minmax(0px,34px) 0px"
        >
          <div
            className={section2Styles.content_box}
            onClick={() => window.open("/About", "_self")}
          >
            <div
              onClick={() => window.open("/About", "_self")}
              className={section2Styles.flexRow3}
            >
              {/* <div className={section2Styles.flexRow3__cell}> */}
              <h3
                className={`${section2Styles.subtitle4} ${section2Styles.flexRow3__cell}`}
              >
                {t("homepages.about_us.more_about_us")}
              </h3>
              {/* </div> */}
              <div className={section2Styles.flexRow3__cell1}>
                <img
                  src={require("assets/d46a90d42b6aeb9c0e8aa18a745d77aa.png")}
                  alt="alt text"
                  className={section2Styles.image2}
                />
              </div>
            </div>
          </div>
        </px-grid>
      </div>
    </section>
  );
}

function RenderSection3(props) {
  const { t } = useTranslation();
  useEffect(() => {
    // define a callback function to be called when a DOM change is observed
    const handleMutations = () => {
      let videoTags = document.querySelectorAll("video"); // update the videoTags variable with the updated DOM
      videoTags.forEach((vid) => {
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("micromessenger") !== -1) {
          // Enable the controls on WeChat browser
          vid.controls = true;
        } else {
          // Disable the controls on other browsers
          vid.controls = false;
          vid.removeAttribute("controlsList");
        }
      });
    };

    // create a new MutationObserver and attach it to the DOM node you want to observe
    const observer = new MutationObserver(handleMutations);
    observer.observe(document.body, { childList: true, subtree: true });

    // call the callback function manually for the initial rendering
    handleMutations();

    // cleanup function to disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  return (
    <section className={section3Styles.section3}>
      <div className={section3Styles.flexCol}>
        <div className={section3Styles.flexCol1}>
          <div className={section3Styles.flexRow}>
            {/* <div className={section3Styles.flexRow__cell}> */}
            <h1 className={section3Styles.title1}>
              {t("homepages.services.Services")}
            </h1>
            {/* </div> */}
            <div className={section3Styles.flexRow__cell1}>
              <hr size={1} className={section3Styles.line1} />
            </div>
            <div className={section3Styles.flexRow__cell2}>
              <img
                src={require("assets/53232d094cf8e3f19a6d1b63363ebc42.png")}
                alt="alt text"
                className={section3Styles.image4}
              />
            </div>
          </div>

          <h1 className={section3Styles.hero_title2}>
            {t("homepages.services.our_solutions")}
          </h1>

          <div className={section3Styles.flexRow1}>
            {/* <div className={section3Styles.flexRow1__cell}> */}
            <div
              className={`${section3Styles.flexCol2} ${section3Styles.flexRow1__cell}`}
            >
              <div className={section3Styles.flexRow2}>
                <div className={section3Styles.flexRow2__cell}>
                  <img
                    src={require("assets/2dc5691588a9f8695003a8e6b209024f.png")}
                    alt="alt text"
                    className={section3Styles.icon1}
                  />
                </div>
                <div className={section3Styles.flexRow2__cell1}>
                  {/* <div className={section3Styles.flexCol3}> */}
                  <h3 className={section3Styles.subtitle5}>
                    {t("homepages.services.market")}
                  </h3>
                  <h5 className={section3Styles.highlights}>
                    {t("homepages.services.market_content")}
                  </h5>
                  {/* </div> */}
                </div>
              </div>

              <div className={section3Styles.flexRow3}>
                <div className={section3Styles.flexRow3__cell}>
                  <img
                    src={require("assets/84509e41701c04216835303d83ec15e5.png")}
                    alt="alt text"
                    className={section3Styles.icon11}
                  />
                </div>
                <div className={section3Styles.flexRow3__cell1}>
                  {/* <div className={section3Styles.flexCol3}> */}
                  <h3 className={section3Styles.subtitle51}>
                    {t("homepages.services.consultant")}
                  </h3>
                  <h5 className={section3Styles.highlights1}>
                    {t("homepages.services.consultant_content")}
                  </h5>
                  {/* </div> */}
                </div>
              </div>
            </div>
            {/* </div> */}
            <div className={section3Styles.flexRow1__spacer} />
            <div className={section3Styles.flexRow1__cell1 + " watermark"}>
              <video
                src={require("assets/msservices.mp4")}
                type="video/mp4"
                alt="msservices"
                className={section3Styles.image}
                autoPlay={true}
                muted={true}
                loop
                playsInline={true}
                onContextMenu={(e) => e.preventDefault()}
                controls
                controlsList="nodownload"
              />
            </div>
          </div>
        </div>

        <px-grid
          x="0.15% 18.29% 85.57%"
          y="104px 53fr 0px"
          xl-x="0.15% 18.17% 81.68%"
          xl-y="104px 53fr 0px"
          lg-x="0.15% 19.56% 80.29%"
          lg-y="104px 53fr 0px"
          md-x="511fr 24.07% 511fr"
          md-y="0px 53fr 0px"
          sm-x="433fr 29.84% 463fr"
          sm-y="0px minmax(0px,34px) 0px"
          xs-x="449fr 27.63% 444fr"
          xs-y="0px minmax(0px,34px) 0px"
          xxs-x="411fr 33.17% 413fr"
          xxs-y="0px minmax(0px,34px) 0px"
          tn-x="343fr 40.16% 341fr"
          tn-y="0px minmax(0px,34px) 0px"
        >
          <div
            className={section3Styles.content_box1}
            onClick={() => window.open("/Services ", "_self")}
          >
            <div
              onClick={() => window.open("/Services ", "_self")}
              className={section3Styles.flexRow4}
            >
              {/* <div className={section3Styles.flexRow4__cell}> */}
              <h3 className={section3Styles.subtitle4}>
                {t("homepages.services.view_more")}
              </h3>
              {/* </div> */}
              <div className={section3Styles.flexRow4__cell1}>
                <img
                  src={require("assets/d46a90d42b6aeb9c0e8aa18a745d77aa.png")}
                  alt="alt text"
                  className={section3Styles.image2}
                />
              </div>
            </div>
          </div>
        </px-grid>
      </div>
    </section>
  );
}

export default function Homepage(props) {
  return (
    <React.Fragment>
      <Headroom tag="header" className="page-header">
        <nav className="max:show lg:hide">
          <Header1 />
        </nav>
        <nav className="lg:show">
          <Header2 />
        </nav>
      </Headroom>

      <main className={cn(styles.main, "homepage")}>
        {/* <div className={styles.main__item}>{RenderSection1(props)}</div>
        <div className={styles.main__item}>{RenderSection2(props)}</div>
        <div className={styles.main__item}>{RenderSection3(props)}</div>
        <div className={styles.section4}>
          <FOOTER />
        </div> */}
        {RenderSection1(props)}
        {RenderSection2(props)}
        {RenderSection3(props)}
        <FOOTER />
      </main>
    </React.Fragment>
  );
}

Homepage.inStorybook = true;
