import React from "react";
import cn from "classnames";
import FOOTER from "components/FOOTER";
import Headroom from "react-headroom";
import Header1 from "components/Header1";
import Header2 from "components/Header2";
import { useTranslation } from "react-i18next";

import section1Styles from "./Privacyandpolicy_section1.module.scss";
import styles from "./Privacyandpolicy.module.scss";

function RenderSection1(props) {
  const { t } = useTranslation();
  return (
    <section className={`${section1Styles.section1} ${section1Styles.flexCol}`}>
      {/* <div className={section1Styles.flexCol}> */}
      <div className={section1Styles.flexCol1}>
        <div className={section1Styles.flexCol2}>
          <h1 className={section1Styles.hero_title}>
            {t("privacy.privacy_policy.title")}
          </h1>
          <h3 className={section1Styles.subtitle}>
            {t("privacy.privacy_policy.title")}
          </h3>
        </div>

        <h3 className={section1Styles.subtitle1}>
          {t("privacy.privacy_policy.describes")}
        </h3>
      </div>

      <div className={section1Styles.flexCol3}>
        <h2 className={section1Styles.medium_title}>
          {t("privacy.interpretation_definitions.title")}
        </h2>

        <div className={section1Styles.flexRow}>
          <div className={section1Styles.flexRow__item}>
            <px-grid
              track-style="flex-grow: 1;"
              x="0px 592fr 0px"
              y="0px 476fr 47px"
              xl-x="0px 592fr 0px"
              xl-y="0px minmax(314px,476fr) 0px"
              sm-x="0px 592fr 0px"
              sm-y="0px minmax(215px,476fr) 0px"
            >
              <h4
                className={`${section1Styles.highlights1_box} ${section1Styles.highlights1}`}
              >
                {/* <span className={section1Styles.highlights1}> */}
                <span className={section1Styles.highlights1_span0}>
                  {t("privacy.interpretation_definitions.interpretation")}
                  <br />
                </span>
                <span className={section1Styles.highlights1_span1}>
                  {t(
                    "privacy.interpretation_definitions.interpretation_content"
                  )}
                  <br />
                  <br />
                </span>
                <span className={section1Styles.highlights1_span2}>
                  {t("privacy.interpretation_definitions.definitions")}
                  <br />
                </span>
                <span className={section1Styles.highlights1_span3}>
                  {t("privacy.interpretation_definitions.purposes")}
                  <br />
                </span>
                <span className={section1Styles.highlights1_span4}>
                  {t("privacy.interpretation_definitions.account")}
                </span>
                <span className={section1Styles.highlights1_span5}>
                  {t("privacy.interpretation_definitions.account_means")}
                  <br />
                </span>
                <span className={section1Styles.highlights1_span6}>
                  {t("privacy.interpretation_definitions.affiliate")}
                </span>
                <span className={section1Styles.highlights1_span7}>
                  {t("privacy.interpretation_definitions.affiliate_means")}
                  <br />
                </span>
                <span className={section1Styles.highlights1_span8}>
                  {t("privacy.interpretation_definitions.company")}
                </span>
                <span className={section1Styles.highlights1_span9}>
                  {t("privacy.interpretation_definitions.company_means")}
                  <br />
                </span>
                <span className={section1Styles.highlights1_span10}>
                  {t("privacy.interpretation_definitions.cookies")}
                </span>
                <span className={section1Styles.highlights1_span11}>
                  {t("privacy.interpretation_definitions.cookies_means")}
                  <br />
                </span>
                {/* </span> */}
              </h4>
            </px-grid>
          </div>
          <div className={section1Styles.flexRow__spacer} />
          <div className={section1Styles.flexRow__item}>
            <px-grid
              track-style="flex-grow: 1;"
              x="0px 592fr 0px"
              y="0px 523fr 0px"
              xl-x="0px 592fr 0px"
              xl-y="0px minmax(335px,523fr) 0px"
              xs-x="0px 592fr 0px"
              xs-y="0px minmax(385px,523fr) 0px"
              tn-x="0px 592fr 0px"
              tn-y="0px minmax(484px,523fr) 0px"
            >
              <h4
                className={`${section1Styles.highlights2_box} ${section1Styles.highlights2}`}
              >
                {/* <span className={section1Styles.highlights2}> */}
                <span className={section1Styles.highlights2_span0}>
                  {t("privacy.interpretation_definitions.country")}
                </span>
                <span className={section1Styles.highlights2_span1}>
                  {t("privacy.interpretation_definitions.country_means")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span2}>
                  {t("privacy.interpretation_definitions.device")}
                </span>
                <span className={section1Styles.highlights2_span3}>
                  {t("privacy.interpretation_definitions.Device_means")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span4}>
                  {t("privacy.interpretation_definitions.personal_Data")}
                </span>
                <span className={section1Styles.highlights2_span5}>
                  {t("privacy.interpretation_definitions.personal_Data_means")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span6}>
                  {t("privacy.interpretation_definitions.service")}
                </span>
                <span className={section1Styles.highlights2_span7}>
                  {t("privacy.interpretation_definitions.service_means")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span8}>
                  {t("privacy.interpretation_definitions.service_Provider")}
                </span>
                <span className={section1Styles.highlights2_span9}>
                  {t(
                    "privacy.interpretation_definitions.service_Provider_means"
                  )}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span10}>
                  {t("privacy.interpretation_definitions.Third_party")}
                </span>
                <span className={section1Styles.highlights2_span11}>
                  {t("privacy.interpretation_definitions.Third_party_means")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span12}>
                  {t("privacy.interpretation_definitions.usage_Data")}
                </span>
                <span className={section1Styles.highlights2_span13}>
                  {t("privacy.interpretation_definitions.usage_Data_means")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span14}>
                  {t("privacy.interpretation_definitions.website")}
                </span>
                <span className={section1Styles.highlights2_span15}>
                  {t("privacy.interpretation_definitions.website_means")}
                </span>
                <span className={section1Styles.highlights2_span16}>
                  {t("privacy.interpretation_definitions.url")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span18}>
                  {t("privacy.interpretation_definitions.you")}
                </span>
                <span className={section1Styles.highlights2_span17}>
                  {t("privacy.interpretation_definitions.you_means")}
                </span>
                {/* </span> */}
              </h4>
            </px-grid>
          </div>
        </div>
      </div>

      <div className={section1Styles.flexCol4}>
        <h2 className={section1Styles.medium_title1}>
          {t("privacy.data_collected.title")}
        </h2>

        <div className={section1Styles.flexRow1}>
          <div className={section1Styles.flexRow1__item}>
            <px-grid
              track-style="flex-grow: 1;"
              x="0px 592fr 0px"
              y="0px 3556fr 0px"
              xl-x="0px 592fr 0px"
              xl-y="0px minmax(2587px,3556fr) 0px"
              sm-x="0px 592fr 0px"
              sm-y="0px minmax(2026px,3556fr) 0px"
            >
              <h4
                className={`${section1Styles.highlights2_box} ${section1Styles.highlights2}`}
              >
                {/* <span className={section1Styles.highlights2}> */}
                <span className={section1Styles.highlights2_span0}>
                  {t("privacy.data_collected.personal_data.title")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span1}>
                  {t(
                    "privacy.data_collected.personal_data.personal_data_means"
                  )}
                  <br />
                  {t("privacy.data_collected.personal_data.email")}
                  <br />
                  {t("privacy.data_collected.personal_data.name")}
                  <br />
                  {t("privacy.data_collected.personal_data.phone_number")}
                  <br />
                  {t("privacy.data_collected.personal_data.usage_data")}
                  <br />
                  <br />
                </span>
                <span className={section1Styles.highlights2_span2}>
                  {t("privacy.data_collected.usage_data.title")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span3}>
                  {t("privacy.data_collected.usage_data.collected_data")}
                  <br />
                  {t("privacy.data_collected.usage_data.usage_data_means")}
                  <br />
                  {t("privacy.data_collected.usage_data.access_Service")}
                  <br />
                  {t("privacy.data_collected.usage_data.also_collect")}
                  <br />
                  <br />
                </span>
                <span className={section1Styles.highlights2_span4}>
                  {t("privacy.data_collected.Third_Party.title")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span5}>
                  {t("privacy.data_collected.Third_Party.Third_Party_content1")}
                  <br />
                  {t("privacy.data_collected.Third_Party.google")}
                  <br />
                  {t("privacy.data_collected.Third_Party.facebook")}
                  <br />
                  {t("privacy.data_collected.Third_Party.twitter")}
                  <br />
                  {t("privacy.data_collected.Third_Party.linkedIn")}
                  <br />
                  {t("privacy.data_collected.Third_Party.Third_Party_content2")}
                  <br />
                  {t("privacy.data_collected.Third_Party.Third_Party_content3")}
                  <br />
                  <br />
                </span>
                <span className={section1Styles.highlights2_span6}>
                  {t("privacy.data_collected.tracking_technologies.title")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span7}>
                  {t(
                    "privacy.data_collected.tracking_technologies.use_cookies"
                  )}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span8}>
                  {t("privacy.data_collected.tracking_technologies.cookies")}
                </span>
                <span className={section1Styles.highlights2_span9}>
                  {t(
                    "privacy.data_collected.tracking_technologies.cookies_means"
                  )}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span10}>
                  {t(
                    "privacy.data_collected.tracking_technologies.web_beacons"
                  )}
                </span>
                <span className={section1Styles.highlights2_span11}>
                  {t(
                    "privacy.data_collected.tracking_technologies.web_beacons_means"
                  )}
                  <br />
                  <br />
                  {t(
                    "privacy.data_collected.tracking_technologies.cookies_effect"
                  )}
                </span>
                <span className={section1Styles.highlights2_span12}>
                  {t(
                    "privacy.data_collected.tracking_technologies.termsFeed_website"
                  )}
                </span>
                <span className={section1Styles.highlights2_span13}>
                  {t("privacy.data_collected.tracking_technologies.article")}
                  <br />
                  {t("privacy.data_collected.tracking_technologies.out_below")}
                  <br />
                  <br />
                </span>
                <span className={section1Styles.highlights2_span14}>
                  {t("privacy.data_collected.necessary_cookies.title")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span15}>
                  {t(
                    "privacy.data_collected.necessary_cookies.session_cookies"
                  )}
                  <br />
                  {t(
                    "privacy.data_collected.necessary_cookies.session_cookies_administered"
                  )}
                  <br />
                  {t(
                    "privacy.data_collected.necessary_cookies.session_cookies_purpose"
                  )}
                  <br />
                  {t("privacy.data_collected.necessary_cookies.cookies_policy")}
                  <br />
                  {t(
                    "privacy.data_collected.necessary_cookies.persistent_cookies"
                  )}
                  <br />
                  {t(
                    "privacy.data_collected.necessary_cookies.persistent_cookies_administered"
                  )}
                  <br />
                  {t(
                    "privacy.data_collected.necessary_cookies.persistent_cookies_purpose"
                  )}
                  <br />
                  <br />
                </span>
                <span className={section1Styles.highlights2_span16}>
                  {t("privacy.data_collected.functionality_cookies.title")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span17}>
                  {t(
                    "privacy.data_collected.functionality_cookies.functionality_cookies"
                  )}
                  <br />
                  {t(
                    "privacy.data_collected.functionality_cookies.functionality_cookies_administered"
                  )}
                  <br />
                  {t(
                    "privacy.data_collected.functionality_cookies.functionality_cookies_purpose"
                  )}
                  <br />
                  {t(
                    "privacy.data_collected.functionality_cookies.more_cookies_information"
                  )}
                  <br />
                  <br />
                </span>
                <span className={section1Styles.highlights2_span18}>
                  {t("privacy.data_collected.use_data.title")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span19}>
                  {t("privacy.data_collected.use_data.use_personal_purposes")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span20}>
                  {t("privacy.data_collected.use_data.provide")}
                </span>
                <span className={section1Styles.highlights2_span21}>
                  {t("privacy.data_collected.use_data.including")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span22}>
                  {t("privacy.data_collected.use_data.manage_account")}
                </span>
                <span className={section1Styles.highlights2_span23}>
                  {t("privacy.data_collected.use_data.manage_account_content")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span24}>
                  {t("privacy.data_collected.use_data.performance_contract")}
                </span>
                <span className={section1Styles.highlights2_span25}>
                  {t(
                    "privacy.data_collected.use_data.performance_contract_content"
                  )}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span26}>
                  {t("privacy.data_collected.use_data.contact_you")}
                </span>
                <span className={section1Styles.highlights2_span27}>
                  {t("privacy.data_collected.use_data.contact_you_content")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span28}>
                  {t("privacy.data_collected.use_data.provide_you")}
                </span>
                <span className={section1Styles.highlights2_span29}>
                  {t("privacy.data_collected.use_data.provide_you_content")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span30}>
                  {t("privacy.data_collected.use_data.manage_requests")}
                </span>
                <span className={section1Styles.highlights2_span31}>
                  {t("privacy.data_collected.use_data.manage_requests_content")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span32}>
                  {t("privacy.data_collected.use_data.business_transfers1")}
                </span>
                <span className={section1Styles.highlights2_span33}>
                  {t(
                    "privacy.data_collected.use_data.business_transfers_content"
                  )}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span34}>
                  {t("privacy.data_collected.use_data.other_purposes")}
                </span>
                <span className={section1Styles.highlights2_span35}>
                  {t("privacy.data_collected.use_data.other_purposes_content")}
                  <br />
                  <br />
                  {t("privacy.data_collected.use_data.share")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span36}>
                  {t("privacy.data_collected.use_data.service_providers")}
                </span>
                <span className={section1Styles.highlights2_span37}>
                  {t(
                    "privacy.data_collected.use_data.service_providers_content"
                  )}
                  <br />
                  {/* {t("privacy.data_collected.use_data.business_transfers2")}
                      {t("privacy.data_collected.use_data.business_transfers2_content")}
                      <br /> */}
                </span>
                <span className={section1Styles.highlights2_span36}>
                  {t("privacy.data_collected.use_data.business_transfers2")}
                </span>
                <span className={section1Styles.highlights2_span37}>
                  {t(
                    "privacy.data_collected.use_data.business_transfers2_content"
                  )}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span38}>
                  {t("privacy.data_collected.use_data.with_affiliates")}
                </span>
                <span className={section1Styles.highlights2_span39}>
                  {t("privacy.data_collected.use_data.with_affiliates_content")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span40}>
                  {t("privacy.data_collected.use_data.business_partners")}
                </span>
                <span className={section1Styles.highlights2_span41}>
                  {t(
                    "privacy.data_collected.use_data.business_partners_content"
                  )}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span42}>
                  {t("privacy.data_collected.use_data.other_users")}
                </span>
                <span className={section1Styles.highlights2_span43}>
                  {t("privacy.data_collected.use_data.other_users_content")}
                  <br />
                </span>
                <span className={section1Styles.highlights2_span44}>
                  {t("privacy.data_collected.use_data.your_consent")}
                </span>
                <span className={section1Styles.highlights2_span45}>
                  {t("privacy.data_collected.use_data.your_consent_content")}
                  <br />
                </span>
                {/* </span> */}
              </h4>
            </px-grid>
          </div>
          <div className={section1Styles.flexRow1__spacer} />
          <div className={section1Styles.flexRow1__item}>
            {/* <div className={section1Styles.flexCol5}> */}
            <px-grid
              x="0px 592fr 0px"
              y="0px 1519fr 0px"
              xl-x="0px 592fr 0px"
              xl-y="0px minmax(1094px,1519fr) 0px"
              sm-x="0px 592fr 0px"
              sm-y="0px minmax(903px,1519fr) 0px"
            >
              <h4
                className={`${section1Styles.highlights11_box} ${section1Styles.highlights11}`}
              >
                {/* <span className={section1Styles.highlights11}> */}
                <span className={section1Styles.highlights11_span0}>
                  {t("privacy.data_collected.retention_data.title")}
                  <br />
                </span>
                <span className={section1Styles.highlights11_span1}>
                  {t("privacy.data_collected.retention_data.content1")}
                  <br />
                  {t("privacy.data_collected.retention_data.content2")}
                  <br />
                  <br />
                </span>
                <span className={section1Styles.highlights11_span2}>
                  {t("privacy.data_collected.transfer_data.title")}
                  <br />
                </span>
                <span className={section1Styles.highlights11_span3}>
                  {t("privacy.data_collected.transfer_data.content1")}
                  <br />
                  {t("privacy.data_collected.transfer_data.content2")}
                  <br />
                  {t("privacy.data_collected.transfer_data.content3")}
                  <br />
                  <br />
                </span>
                <span className={section1Styles.highlights11_span4}>
                  {t("privacy.data_collected.delete_data.title")}
                  <br />
                </span>
                <span className={section1Styles.highlights11_span5}>
                  {t("privacy.data_collected.delete_data.content1")}
                  <br />
                  {t("privacy.data_collected.delete_data.content2")}
                  <br />
                  {t("privacy.data_collected.delete_data.content3")}
                  <br />
                  {t("privacy.data_collected.delete_data.content4")}
                  <br />
                  <br />
                </span>
                <span className={section1Styles.highlights11_span6}>
                  {t("privacy.data_collected.disclosure_data.title")}
                  <br />
                </span>
                <span className={section1Styles.highlights11_span7}>
                  {t(
                    "privacy.data_collected.disclosure_data.business_transactions"
                  )}
                  <br />
                </span>
                <span className={section1Styles.highlights11_span8}>
                  {t(
                    "privacy.data_collected.disclosure_data.business_transactions_content"
                  )}
                  <br />
                </span>
                <span className={section1Styles.highlights11_span9}>
                  {t("privacy.data_collected.disclosure_data.law_enforcement")}
                  <br />
                </span>
                <span className={section1Styles.highlights11_span10}>
                  {t(
                    "privacy.data_collected.disclosure_data.law_enforcement_content"
                  )}
                  <br />
                </span>
                <span className={section1Styles.highlights11_span11}>
                  {t(
                    "privacy.data_collected.disclosure_data.legal_requirements"
                  )}
                  <br />
                </span>
                <span className={section1Styles.highlights11_span12}>
                  {t(
                    "privacy.data_collected.disclosure_data.legal_requirements_content"
                  )}
                  <br />
                  {t("privacy.data_collected.disclosure_data.action1")}
                  <br />
                  {t("privacy.data_collected.disclosure_data.action2")}
                  <br />
                  {t("privacy.data_collected.disclosure_data.action3")}
                  <br />
                  {t("privacy.data_collected.disclosure_data.action4")}
                  <br />
                  {t("privacy.data_collected.disclosure_data.action5")}
                  <br />
                </span>
                <span className={section1Styles.highlights11_span13}>
                  {t("privacy.data_collected.disclosure_data.security")}
                  <br />
                </span>
                <span className={section1Styles.highlights11_span14}>
                  {t("privacy.data_collected.disclosure_data.security_content")}
                </span>
                {/* </span> */}
              </h4>
            </px-grid>
            <h2 className={section1Styles.medium_title2}>
              {t("privacy.children’s_pricavy.title")}
            </h2>
            <h4 className={section1Styles.highlights3}>
              {t("privacy.children’s_pricavy.content1")}
              <br />
              {t("privacy.children’s_pricavy.content2")}
            </h4>
            <h2 className={section1Styles.medium_title3}>
              {t("privacy.websites_links.title")}
            </h2>
            <h4 className={section1Styles.highlights31}>
              {t("privacy.websites_links.content1")}
              <br />
              {t("privacy.websites_links.content2")}
            </h4>
            <h2 className={section1Styles.medium_title4}>
              {t("privacy.changes_privacy_policy.title")}
            </h2>
            <h4 className={section1Styles.highlights32}>
              {t("privacy.changes_privacy_policy.content1")}
              <br />
              {t("privacy.changes_privacy_policy.content2")}
              <br />
              {t("privacy.changes_privacy_policy.content3")}
            </h4>
            <h2 className={section1Styles.medium_title5}>
              {t("privacy.contact_us.title")}
            </h2>
            <h4
              className={`${section1Styles.highlights33_box} ${section1Styles.highlights33}`}
            >
              {/* <span className={section1Styles.highlights33}> */}
              <span className={section1Styles.highlights33_span0}>
                {t("privacy.contact_us.content1")}
                <br />
                {t("privacy.contact_us.content2")}
                <br />
                {t("privacy.contact_us.content3")}
              </span>
              <span className={section1Styles.highlights33_span1}>
                {t("privacy.contact_us.content4")}
                <br />
              </span>
              <span className={section1Styles.highlights33_span2}>
                {t("privacy.contact_us.content5")}
              </span>
              {/* </span> */}
            </h4>
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* </div> */}
    </section>
  );
}

export default function Privacyandpolicy(props) {
  return (
    <React.Fragment>
      <Headroom tag="header" className="page-header">
        <nav className="max:show lg:hide">
          <Header1 />
        </nav>
        <nav className="lg:show">
          <Header2 />
        </nav>
      </Headroom>

      <main className={cn(styles.main, "privacyandpolicy")}>
        {/* <div className={styles.main__item}>{RenderSection1(props)}</div>
        <div className={styles.section2}>
          <FOOTER />
        </div> */}
        {RenderSection1(props)}
        <FOOTER />
      </main>
    </React.Fragment>
  );
}

Privacyandpolicy.inStorybook = true;
