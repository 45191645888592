// import React, { useEffect } from "react";
import React, { useState } from "react";
import cn from "classnames";
import FOOTER from "components/FOOTER";
import Headroom from "react-headroom";
import Header1 from "components/Header1";
import Header2 from "components/Header2";
import { useTranslation } from "react-i18next";

import section1Styles from "./Contactuspagev2_section1.module.scss";
import styles from "./Contactuspagev2.module.scss";
import axios from "axios";

import { useHistory } from "react-router-dom";

function RenderSection1(props) {
  const { t } = useTranslation();
  const history = useHistory();
  // 用户名
  const [username, setUsername] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  return (
    <section className={section1Styles.section1}>
      <div className={section1Styles.flexCol}>
        <div className={section1Styles.flexRow}>
          <div className={section1Styles.flexRow__item}>
            {/* <div className={section1Styles.flexCol1}> */}
            {/* <div className={section1Styles.flexCol1__item}> */}
            <h3
              className={`${section1Styles.subtitle} ${section1Styles.flexCol1__item}`}
            >
              {t("contact_us.touch")}
            </h3>
            {/* </div> */}
            {/* <div className={section1Styles.flexCol1__item1}> */}
            <h1 className={section1Styles.hero_title}>
              {t("contact_us.together")}
            </h1>
            {/* </div> */}
            {/* <div className={section1Styles.flexCol1__item2}> */}
            <h3 className={section1Styles.subtitle2}>{t("contact_us.hear")}</h3>
            {/* </div> */}
            {/* </div> */}
          </div>
          <div className={section1Styles.flexRow__spacer} />
          {/* <div className={section1Styles.flexRow__item1}> */}
          <div
            className={`${section1Styles.flexCol2} ${section1Styles.flexRow__item1}`}
          >
            <h3 className={section1Styles.subtitle1}>
              {t("contact_us.contact")}
            </h3>
            <h4 className={section1Styles.highlights3}>info@milessun.org</h4>
            <h4 className={section1Styles.highlights2}>+6590258683</h4>
          </div>
          {/* </div> */}
        </div>

        <div className={section1Styles.content_box2}>
          <div className={section1Styles.flexCol3}>
            <h1 className={section1Styles.hero_title1}>
              {t("contact_us.project")}
            </h1>
            <div className={section1Styles.flexRow1}>
              <div className={section1Styles.flexCol4}>
                <h3 className={section1Styles.subtitle3}>
                  {t("contact_us.name")}
                </h3>
                <input
                  className={section1Styles.input1}
                  type="text"
                  name="name"
                  placeholder=""
                  value={username}
                  onChange={function (e) {
                    setUsername(e.target.value);
                  }}
                />
                <hr size={1} className={section1Styles.line1} />
              </div>

              <div className={section1Styles.flexRow1__spacer} />

              <div className={section1Styles.flexCol5}>
                <h3 className={section1Styles.subtitle31}>
                  {t("contact_us.Company")}
                </h3>
                <input
                  className={section1Styles.input1}
                  type="text"
                  name="company"
                  placeholder=""
                  value={company}
                  onChange={function (e) {
                    setCompany(e.target.value);
                  }}
                />
                <hr size={1} className={section1Styles.line2} />
              </div>
            </div>

            <div className={section1Styles.flexRow2}>
              <div className={section1Styles.flexCol4}>
                <h3 className={section1Styles.subtitle3}>
                  {t("contact_us.email")}
                </h3>
                <input
                  className={section1Styles.input1}
                  type="email"
                  name="email"
                  placeholder=""
                  value={email}
                  onChange={function (e) {
                    setEmail(e.target.value);
                  }}
                />
                <hr size={1} className={section1Styles.line1} />
              </div>

              <div className={section1Styles.flexRow2__spacer} />

              <div className={section1Styles.flexCol5}>
                <h3 className={section1Styles.subtitle31}>
                  {t("contact_us.phone")}
                </h3>
                <input
                  className={section1Styles.input1}
                  type="number"
                  name="phone"
                  placeholder=""
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  value={phone}
                  onChange={function (e) {
                    setPhone(e.target.value);
                  }}
                />
                <hr size={1} className={section1Styles.line2} />
              </div>
            </div>

            <div className={section1Styles.content_box1}>
              <h3 className={section1Styles.subtitle32}> </h3>
              <textarea
                className={section1Styles.input2}
                type="text"
                name="message"
                placeholder={t("contact_us.your_project")}
                value={message}
                onChange={function (e) {
                  setMessage(e.target.value);
                }}
              />
            </div>

            <px-grid
              x="0.4% 15.47% 84.13%"
              y="58px 53fr 0px"
              xl-x="0.4% 19.01% 80.59%"
              xl-y="58px 53fr 0px"
              lg-x="0.4% 22.14% 77.45%"
              lg-y="58px 53fr 0px"
              md-x="0.41% 20.86% 78.73%"
              md-y="56px minmax(0px,33px) 0px"
              sm-x="0.41% 24.77% 74.82%"
              sm-y="56px minmax(0px,33px) 0px"
              xxs-x="0.41% 26.82% 72.76%"
              xxs-y="36px minmax(0px,33px) 0px"
              tn-x="0.41% 30.83% 68.76%"
              tn-y="28px minmax(0px,33px) 0px"
            >
              <div className={section1Styles.content_box11}>
                <button
                  type="button"
                  className={section1Styles.subtitle4}
                  onClick={function () {
                    console.log(username, company, email, phone, message);
                    const data = {
                      username,
                      email,
                      company,
                      phone,
                      message,
                      source: "Miles Sun Overseas",
                    };
                    if (username && company && email && phone && message) {
                      axios
                        .post("/user/submit", data)
                        .then((response) => {
                          console.log(
                            "User data successfully submitted:",
                            response.data
                          );
                          alert(`${t("hint.success")}`);
                          history.push("/");
                        })
                        .catch((error) => {
                          console.error("Error submitting user data:", error);
                        });
                    } else {
                      alert(`${t("hint.fail")}`);
                    }
                  }}
                >
                  {t("contact_us.submit")}
                </button>
              </div>
            </px-grid>
          </div>
        </div>
      </div>
    </section>
  );
}

export default function Contactuspagev2(props) {
  return (
    <React.Fragment>
      <Headroom tag="header" className="page-header">
        <nav className="max:show lg:hide">
          <Header1 />
        </nav>
        <nav className="lg:show">
          <Header2 />
        </nav>
      </Headroom>

      <main className={cn(styles.main, "contactuspagev2")}>
        {/* <div className={styles.main__item}>{RenderSection1(props)}</div>
        <div className={styles.section2}>
          <FOOTER />
        </div> */}
        {RenderSection1(props)}
          <FOOTER />
      </main>
    </React.Fragment>
  );
}

Contactuspagev2.inStorybook = true;
