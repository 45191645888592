import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Privacyandpolicy from "components/Privacyandpolicy";

import Ourservices from "components/Ourservices";
import Homepage from "components/Homepage";

import Contactuspagev2 from "components/Contactuspagev2";
import Companies from "components/Companies";
import Careerspage from "components/Careerspage";
import About from "components/Aboutuspage";
import OurWork from "components/OurWork";
import Payment from "components/PaymentPage";

import Animation from "components/OurWork_animation";
import Photography from "components/OurWork_photography";
import Poster from "components/OurWork_poster";
import Branding from "components/OurWork_branding";

import TheNakedHouse from "components/TheNakedHouse";
import WabiSabi from "components/WabiSabi";

import AOS from "aos";
import { isMobile } from "react-device-detect";
import cookies from "js-cookie";

import "aos/dist/aos.css";
import "./fonts.css";

class App extends Component {
  constructor(props) {
    super(props);
    document.title = cookies.get("i18next") === "en" ? "Miles Sun Overseas" : "里陽海外";
  }
  
  componentDidMount() {
    setTimeout(() => {
      AOS.init({
        offset: isMobile ? 10 : 100,
      });
      AOS.refresh();
    }, 1500);
  }

  render() {
    return (
      <Router hashType="noslash" basename={process.env.BASE_PATH}>
        <Switch>
          <Route exact path="/list">
            <div>
              {/* pxCode Screen List: <br />
              <Link to="/Privacyandpolicy">Privacyandpolicy</Link>
              <br />
              <Link to="/Ourservices">Ourservices</Link>
              <br />
              <Link to="/">Homepage</Link>
              <br />
              <Link to="/Contactuspage">Contactuspage</Link>
              <br />
              <Link to="/Companies">Companies</Link>
              <br />
              <Link to="/Careerspage">Careerspage</Link>
              <br />
              <Link to="/Aboutuspage">Aboutuspage</Link>
              <br />
              <Link to="/OurWork">OurWork</Link>
              <br />
              <Link to="/Payment">Payment</Link> */}
            </div>
          </Route>

          <Route exact path="/Privacypolicy" component={Privacyandpolicy} />

          <Route exact path="/Services" component={Ourservices} />
          <Route exact path="/" component={Homepage} />

          <Route exact path="/Contact" component={Contactuspagev2} />
          <Route exact path="/Companies" component={Companies} />
          <Route exact path="/Careerspage" component={Careerspage} />
          <Route exact path="/About" component={About} />
          <Route exact path="/Work" component={OurWork} />

          <Route exact path="/Payment" component={Payment} />

          <Route exact path="/Animation" component={Animation} />
          <Route exact path="/Photography" component={Photography} />
          <Route exact path="/Poster" component={Poster} />
          <Route exact path="/Branding" component={Branding} />

          <Route exact path="/Thenakedhouse" component={TheNakedHouse} />
          <Route exact path="/Wabisabi" component={WabiSabi} />
        </Switch>
      </Router>
    );
  }
}

export default App;
