import React from "react";
import cn from "classnames";
import i18n from "i18n";
import { Bag } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import styles from "./Header1.module.scss";

export default function Header1(props) {
  const { t } = useTranslation();
  const currentLanguage = cookies.get("i18next");
  return (
    <div className={cn(styles.root, "header1")}>
      <div className={styles.flexRow}>
        <div className={styles.flexRow__cell}>
          <img
            src={require("assets/011838223a3faa58ed23830d6c8467e9.png")}
            alt="alt text"
            className={styles.image}
            onClick={() => window.open("/", "_self")}
          />
        </div>
        <div className={styles.flexRow__cell1}>
          <div className={styles.flexRow1}>
            {/* <div className={styles.flexRow1__cell}> */}
            <h5
              onClick={() => window.open("/", "_self")}
              className={styles.highlights}
            >
              {t("header1.home")}
            </h5>
            {/* </div> */}
            <div className={styles.flexRow1__spacer1} />
            {/* <div className={styles.flexRow1__cell1}> */}
            <h5
              onClick={() => window.open("/About", "_self")}
              className={styles.highlights}
            >
              {t("header1.about")}
            </h5>
            {/* </div> */}
            <div className={styles.flexRow1__spacer1} />
            {/* <div className={styles.flexRow1__cell2}> */}
            <h5
              onClick={() => window.open("/Services ", "_self")}
              className={styles.highlights}
            >
              {t("header1.services")}
            </h5>
            {/* </div> */}
            <div className={styles.flexRow1__spacer1} />
            {/* <div className={styles.flexRow1__cell4}> */}
            <h5
              onClick={() => window.open("/Work", "_self")}
              className={styles.highlights}
            >
              {t("header1.work")}
            </h5>
            {/* </div> */}
            <div className={styles.flexRow1__spacer1} />

            {/* <div className={styles.flexRow1__cell3}> */}
            <h5
              onClick={() => window.open("/Companies", "_self")}
              className={styles.highlights}
            >
              {t("header1.companies")}
            </h5>
            {/* </div> */}
          </div>
        </div>

        <div className={styles.flexRow__cell2}>
          <div className={styles.flexRow1}>
            {/* <div className={styles.flexRow1__cell3}> */}
            <h5
              onClick={() => window.open("/Contact", "_self")}
              className={styles.contact}
            >
              {t("header1.contacts")}
            </h5>
            {/* </div> */}
            <div className={styles.flexRow1__spacer2} />
            <div className={styles.flexRow1__cell3}>
              <h5 className={styles.highlights}>
                <a href="https://shop.milessun.org/">
                  <Bag color="#5B5C39" size={25} title="bag" />
                </a>
              </h5>
            </div>
            <div className={styles.flexRow1__spacer2} />
            <button
              className={styles.changebtn}
              onClick={() => {
                if (currentLanguage === "en") {
                  i18n.changeLanguage("cn");
                } else {
                  i18n.changeLanguage("en");
                }
                document.title =
                  cookies.get("i18next") === "en"
                    ? "Miles Sun Overseas"
                    : "里陽海外";
              }}
            >
              <div
                style={{
                  "--src": `url(${require("assets/d48ade4cd2a43c9a7ea63212f8b3e953.png")})`,
                }}
                className={styles.icon}
              />
              <span className={styles.language_text}>
                {currentLanguage.toUpperCase()}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

Header1.inStorybook = true;
