import React, { useState, useEffect } from "react";
import $ from "jquery";
import styles from "./Header2.module.scss";

import { Navbar, Container, Offcanvas, Row } from "react-bootstrap";
import { List } from "react-bootstrap-icons";
import { Bag } from "react-bootstrap-icons";
import i18n from "i18n";
import { useTranslation } from 'react-i18next';
import cookies from "js-cookie";

export default function Header2(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation();
  const currentLanguage = cookies.get("i18next");

  window.onclick = (e) => {
    if (e.target.className === "fade offcanvas-backdrop show") {
      handleClose();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const offcanvas = document.querySelector(".fade.offcanvas-backdrop.show");

      if (offcanvas) {
        handleClose();
      }
    };

    $(window).on("resize", handleResize);

    return () => {
      $(window).off("resize", handleResize);
    };
  });

  return (
    <Navbar
      key="xl"
      expand="xl"
      className="mb-3 w-100"
      style={{ backgroundColor: "#FCFCFC" }}
    >
      <Container fluid className=" justify-content-spacebetween">
        <Navbar.Brand>
          <img
            src={require("assets/011838223a3faa58ed23830d6c8467e9.png")}
            alt="alt text"
            className={styles.image}
            onClick={() => window.open("/", "_self")}
          />
        </Navbar.Brand>
        <Navbar.Toggle onClick={handleShow} />
        <List onClick={handleShow} />
      </Container>
      <Offcanvas
        show={show}
        onHide={handleClose}
        backdrop="true"
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container>
            <Row>
              <div className="box">
                {/* <table>
                  <thead>
                    <tr> */}
                      <span className={styles.item_style} onClick={() => window.open("/", "_self")}>{t("header1.home")}</span>
                    {/* </tr>
                  </thead>
                </table> */}
              </div>
            </Row>
            <Row>
              <div className="box">
                {/* <table>
                  <thead>
                    <tr> */}
                      <span className={styles.item_style} onClick={() => window.open("/About", "_self")}>
                        {t("header1.about")}
                      </span>
                    {/* </tr>
                  </thead>
                </table> */}
              </div>
            </Row>
            <Row>
              <div className="box">
                {/* <table>
                  <thead>
                    <tr> */}
                      <span className={styles.item_style} onClick={() => window.open("/Services ", "_self")}>
                        {t("header1.services")}
                      </span>
                    {/* </tr>
                  </thead>
                </table> */}
              </div>
            </Row>
            <Row>
              <div className="box">
                {/* <table>
                  <thead>
                    <tr> */}
                      <span className={styles.item_style} onClick={() => window.open("/Work", "_self")}>
                        {t("header1.work")}
                      </span>
                   {/*  </tr>
                  </thead>
                </table> */}
              </div>
            </Row>
            <Row>
              <div className="box">
                {/* <table>
                  <thead>
                    <tr> */}
                      <span className={styles.item_style} onClick={() => window.open("/Companies", "_self")}>
                        {t("header1.companies")}
                      </span>
                    {/* </tr>
                  </thead>
                </table> */}
              </div>
            </Row>
            <Row>
              <div className="box">
                {/* <table>
                  <thead>
                    <tr> */}
                      <span className={styles.item_style}
                        onClick={() => window.open("/Contact", "_self")}
                      >
                        {t("header1.contacts")}
                      </span>
                    {/* </tr>
                  </thead>
                </table> */}
              </div>
            </Row>
            <Row>
              <div className="box">
                {/* <table>
                  <thead>
                    <tr> */}
                      <span className={styles.item_style}>
                        <a href="https://shop.milessun.org/"><Bag color="#5B5C39" size={25} title="bag" /></a>
                      </span>
                    {/* </tr>
                  </thead>
                </table> */}
              </div>
            </Row>
            <Row>
              <div className="box">
                <table>
                  <thead>
                    <tr>
                      <th onClick={() => {
                        if (currentLanguage === "en") {
                          i18n.changeLanguage("cn");
                        } else {
                          i18n.changeLanguage("en");
                        }
                        document.title = cookies.get("i18next") === "en" ? "Miles Sun Overseas" : "里陽海外";
                      }}>
                        <div className={styles.language_box}>
                        <img className={styles.language_img} src={require("assets/language.jpg")}
                          alt="alt text"></img>
                        <span className={styles.language_text}>{currentLanguage.toUpperCase()}</span>
                        </div>
                        
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
    </Navbar>
  );
}

Header2.inStorybook = true;
