import React, { useEffect } from "react";
import cn from "classnames";
import Footer from "components/FOOTER";
import Headroom from "react-headroom";
import Header1 from "components/Header1";
import Header2 from "components/Header2";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";

import section1Styles from "./OurWork_section1.module.scss";
import section2Styles from "./OurWork_section2.module.scss";
import styles from "./OurWork.module.scss";
import { ArrowUp } from "react-bootstrap-icons";
import ScrollToTop from "react-scroll-to-top";

function RenderSection2(props) {
  const { t } = useTranslation();
  useEffect(() => {
    // define a callback function to be called when a DOM change is observed
    const handleMutations = () => {
      let videoTags = document.querySelectorAll("video"); // update the videoTags variable with the updated DOM
      videoTags.forEach((vid) => {
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("micromessenger") !== -1) {
          // Enable the controls on WeChat browser
          vid.controls = true;
        } else {
          // Disable the controls on other browsers
          vid.controls = false;
          vid.removeAttribute("controlsList");
        }
      });
    };

    // create a new MutationObserver and attach it to the DOM node you want to observe
    const observer = new MutationObserver(handleMutations);
    observer.observe(document.body, { childList: true, subtree: true });

    // call the callback function manually for the initial rendering
    handleMutations();

    // cleanup function to disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smooth scrolling
    });
  };

  return (
    <section className={`${section2Styles.section2} ${section1Styles.flexCol}`}>
      {/* <div className={section1Styles.flexCol}> */}
      <div className={section2Styles.flexCol1}>
        <h1 className={section2Styles.hero_title}>{t("our_work.animation")}</h1>
        <div className={section2Styles.flexRow}>
          <hr size={1} className={section2Styles.line} />
          <div className={section2Styles.flexRow__cell}>
            {/* <LazyLoad> */}
            <img
              src={require("assets/4207dc499c3a3bce491afed60307b70e.png")}
              alt="alt text"
              className={section2Styles.image3}
            />
            {/* </LazyLoad> */}
          </div>
        </div>
      </div>

      <div className={section2Styles.flexRow1}>
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <video
            src={require("assets/design.mp4")}
            type="video/mp4"
            alt="design"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell1}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell1}`}>
          <video
            src={require("assets/lessismore.mp4")}
            type="video/mp4"
            alt="lessismore"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow1__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow1__cell}`}>
          <video
            src={require("assets/love.mp4")}
            type="video/mp4"
            alt="love"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
      </div>

      <div className={section2Styles.flexRow2}>
        {/* <div className={section2Styles.flexRow2__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow2__cell}`}>
          <video
            src={require("assets/naked_house_bubble.mp4")}
            type="video/mp4"
            alt="naked_house_bubble"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow2__cell1}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow2__cell1}`}>
          <video
            src={require("assets/happy_sunday.mp4")}
            type="video/mp4"
            alt="happy_sunday"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow2__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow2__cell}`}>
          <video
            src={require("assets/simply.mp4")}
            type="video/mp4"
            alt="simply"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
      </div>

      <div className={section2Styles.flexRow2}>
        {/* <div className={section2Styles.flexRow3__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow3__cell}`}>
          <video
            src={require("assets/positive.mp4")}
            type="video/mp4"
            alt="positive"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow3__cell1}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow3__cell1}`}>
          <video
            src={require("assets/old_is_new.mp4")}
            type="video/mp4"
            alt="old_is_new"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow3__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow3__cell}`}>
          <video
            src={require("assets/love_timing.mp4")}
            type="video/mp4"
            alt="love_timing"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
      </div>

      <div className={section2Styles.flexRow2}>
        {/* <div className={section2Styles.flexRow4__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow4__cell}`}>
          <video
            src={require("assets/nchy.mp4")}
            type="video/mp4"
            alt="nchy"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow4__cell1}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow4__cell1}`}>
          <video
            src={require("assets/happy_monday.mp4")}
            type="video/mp4"
            alt="happy_monday"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow4__cell}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow4__cell}`}>
          <video
            src={require("assets/simple.mp4")}
            type="video/mp4"
            alt="simple"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
      </div>

      <div className={section2Styles.flexRow2}>
        {/* <div className={secti.on2Styles.flexRow4__cell2}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow4__cell2}`}>
          <video
            src={require("assets/just_do_it.mp4")}
            type="video/mp4"
            alt="just_do_it"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </d.iv> */}
        {/* <div className={section2Styles.flexRow4__cell3}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow4__cell3}`}>
          <video
            src={require("assets/share_together.mp4")}
            type="video/mp4"
            alt="share_together"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
        {/* <div className={section2Styles.flexRow4__cell2}> */}
        <LazyLoad className={`watermark ${section2Styles.flexRow4__cell2}`}>
          <video
            src={require("assets/fukin_great2023.mp4")}
            type="video/mp4"
            alt="fukin_great2023"
            className={section1Styles.image}
            autoPlay={true}
            muted={true}
            loop
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
            controls
            controlsList="nodownload"
          />
        </LazyLoad>
        {/* </div> */}
      </div>

      <div className={section2Styles.ArrowUp} onClick={handleScrollToTop}></div>

      <ScrollToTop
        viewBox="0 0 256 256"
        smooth
        style={{
          borderRadius: "30px",
          backgroundColor: "rgb(45, 60, 31)",
          color: "white",
          borderColor: "rgb(45, 60, 31)",
          borderWidth: "3px",
          borderStyle: "solid",
          marginRight: "3%",
          width: "60px",
          height: "60px",
          border: "3px solid white",
          animation: "linear",
          boxShadow: "none",
        }}
        className={section2Styles.scroll2top}
        component={<ArrowUp size={30} />}
      />
      {/* </div> */}
    </section>
  );
}

export default function OurWork(props) {
  return (
    <React.Fragment>
      <Headroom tag="header" className="page-header">
        <nav className="max:show lg:hide">
          <Header1 />
        </nav>
        <nav className="lg:show">
          <Header2 />
        </nav>
      </Headroom>

      <main className={cn(styles.main, "our-work")}>
        {/* <div className={styles.main__cell}>{RenderSection2(props)}</div>
        <div className={styles.section3}>
          <Footer />
        </div> */}
        {RenderSection2(props)}
        <Footer />
      </main>
    </React.Fragment>
  );
}

OurWork.inStorybook = true;
